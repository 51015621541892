import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from '@material-ui/core/InputAdornment';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FlightLandIcon from '@material-ui/icons/FlightLand';

import { CircularProgress, Tooltip } from "@mui/material";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { SetAirportJourney, SetAirportJourneyRoundtrip, SetAirportJourneyMultiDest, SetFirstRender } from "../../../Actions/Flight";

import axios from "axios";

const useStyles = makeStyles(() => ({
    autoComplete: {
        "&& .mui-jss-MuiAutocomplete-popupIndicator": {
            display: "none"
        }
    },
    textFieldInput: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        // color: '#0000008A',
        paddingTop: 4,
        paddingBottom: 4
    },
    noBorder: {
        borderRadius: 0
    },
    withBorder: {
        borderRadius: "8px 0px 0px 8px"
    },
    withFullBorder: {
        borderRadius: 8
    },
    startAdornment: {
        marginRight: 12
    },
    endAdornment: {
        marginRight: 12
    }
}));

let source = null;

const AirportSearch = ({ display, origin_or_dest, group_index, journey_type, journey_index, journey_airport, label }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const first_render = useSelector(store => store.flight.first_render);
    const language = useSelector(store => store.header.language);
    const flight_param = useSelector(store => store.itinerary_type.flight_circuit);

    const [airportInput, setAirportInput] = useState("");
    const [airportResults, setAirportResults] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (flight_param.length !== 0 && journey_type !== 2) {
            let param = flight_param[0];
            let new_result = [];
            if (origin_or_dest === 'origin' && param.allowed_departure_airport.length !== 0) {
                param.allowed_departure_airport.map((airport) => {
                    let airport_light = {
                        name: airport.name !== null ? airport.name : airport.international_name,
                        city: airport.iata_city.name !== null ? airport.iata_city.name : airport.iata_city.international_name,
                        airport_code: airport.airport_code,
                        iata_city: airport.iata_city
                    };
                    new_result.push(airport_light);
                });
                setAirportResults(new_result);
            }
            if (origin_or_dest === 'destination' && param.allowed_arrival_airport.length !== 0) {
                param.allowed_arrival_airport.map((airport) => {
                    let airport_light = {
                        name: airport.name !== null ? airport.name : airport.international_name,
                        city: airport.iata_city.name !== null ? airport.iata_city.name : airport.iata_city.international_name,
                        airport_code: airport.airport_code,
                        iata_city: airport.iata_city
                    };
                    new_result.push(airport_light);
                });
                setAirportResults(new_result);
            }
        }
    }, []);
    useEffect(() => {
        if (flight_param.length !== 0 && journey_type !== 2) {
            let param = flight_param[0];
            let new_result = [];
            if (journey_index === 0) {
                if (origin_or_dest === 'origin' && param.allowed_departure_airport.length !== 0) {
                    param.allowed_departure_airport.map((airport) => {
                        let airport_light = {
                            name: airport.name !== null ? airport.name : airport.international_name,
                            city: airport.iata_city.name !== null ? airport.iata_city.name : airport.iata_city.international_name,
                            airport_code: airport.airport_code,
                            iata_city: airport.iata_city
                        };
                        new_result.push(airport_light);
                    });
                    setAirportResults(new_result);
                }
                if (origin_or_dest === 'destination' && param.allowed_arrival_airport.length !== 0) {
                    param.allowed_arrival_airport.map((airport) => {
                        let airport_light = {
                            name: airport.name !== null ? airport.name : airport.international_name,
                            city: airport.iata_city.name !== null ? airport.iata_city.name : airport.iata_city.international_name,
                            airport_code: airport.airport_code,
                            iata_city: airport.iata_city
                        };
                        new_result.push(airport_light);
                    });
                    setAirportResults(new_result);
                }
            }
            if (journey_index === 1) {
                if (origin_or_dest === 'origin' && param.allowed_arrival_airport.length !== 0) {
                    param.allowed_arrival_airport.map((airport) => {
                        let airport_light = {
                            name: airport.name !== null ? airport.name : airport.international_name,
                            city: airport.iata_city.name !== null ? airport.iata_city.name : airport.iata_city.international_name,
                            airport_code: airport.airport_code,
                            iata_city: airport.iata_city
                        };
                        new_result.push(airport_light);
                    });
                    setAirportResults(new_result);
                }
                if (origin_or_dest === 'destination' && param.allowed_departure_airport.length !== 0) {
                    param.allowed_departure_airport.map((airport) => {
                        let airport_light = {
                            name: airport.name !== null ? airport.name : airport.international_name,
                            city: airport.iata_city.name !== null ? airport.iata_city.name : airport.iata_city.international_name,
                            airport_code: airport.airport_code,
                            iata_city: airport.iata_city
                        };
                        new_result.push(airport_light);
                    });
                    setAirportResults(new_result);
                }
            }
        }
        if (journey_type === 2) {
            setAirportResults([]);
        }
    }, [journey_type]);
    useEffect(() => {
        if (display && !first_render && airportInput.length >= 3) {
            if (flight_param.length !== 0 && journey_type !== 2) {
                let param = flight_param[0];
                let new_result = [];
                if (origin_or_dest === 'origin' && param.allowed_departure_airport.length !== 0) {
                    param.allowed_departure_airport.map((airport) => {
                        let airport_light = {
                            name: airport.name !== null ? airport.name : airport.international_name,
                            city: airport.iata_city.name !== null ? airport.iata_city.name : airport.iata_city.international_name,
                            airport_code: airport.airport_code,
                            iata_city: airport.iata_city
                        };
                        new_result.push(airport_light);
                    });
                    setAirportResults(new_result);
                } else {
                    getAirportsList();
                }
                if (origin_or_dest === 'destination' && param.allowed_arrival_airport.length !== 0) {
                    param.allowed_arrival_airport.map((airport) => {
                        let airport_light = {
                            name: airport.name !== null ? airport.name : airport.international_name,
                            city: airport.iata_city.name !== null ? airport.iata_city.name : airport.iata_city.international_name,
                            airport_code: airport.airport_code,
                            iata_city: airport.iata_city
                        };
                        new_result.push(airport_light);
                    });
                    setAirportResults(new_result);
                } else {
                    getAirportsList();
                }
            } else {
                getAirportsList();
            }
        }
    }, [airportInput]);

    const changeAirport = (value) => {
        if (journey_type === 1) {
            // roundtrip
            dispatch(SetAirportJourneyRoundtrip(value, group_index, origin_or_dest, journey_index));
        } else if (journey_type === 2) {
            // multi destination
            dispatch(SetAirportJourneyMultiDest(value, group_index, origin_or_dest, journey_index));
        } else if (journey_type === 3) {
            // one way
            dispatch(SetAirportJourney(value, group_index, origin_or_dest, journey_index));
        }
        setLoading(false);
    };

    const inputChange = (event, value) => {
        if (event !== null && event.type === "change") {
            setLoading(true);
            setAirportInput(value);
            if (first_render) {
                dispatch(SetFirstRender(false));
            }
        }
        if (value.length === 0) {
            setLoading(false);
        }
    };

    const getAirportsList = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            if (source !== null) {
                source.cancel('Operation canceled by the user.');
            }
            source = axios.CancelToken.source();
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}iata-airports/?lang=${language}&search=${airportInput}&limit=15`,
                cancelToken: source.token
            }).then(function (response) {
                let new_result = [];
                response.data.results.map((airport) => {
                    let airport_light = {
                        name: airport.name !== undefined && airport.name !== null ? airport.name : airport.international_name,
                        city: airport.iata_city.name !== undefined && airport.iata_city.name !== null ? airport.iata_city.name : airport.iata_city.international_name,
                        airport_code: airport.airport_code,
                        iata_city: airport.iata_city
                    };
                    new_result.push(airport_light);
                });
                setLoading(false);
                setAirportResults(new_result);
            }).catch(function (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log(error.response);
                    setLoading(false);
                }
            });
        }
    };
    return (
        <Autocomplete
            getOptionSelected={(option, value) => option.airport_code === value.airport_code}
            getOptionLabel={(option) => {
                if (option === "") {
                    return "";
                }
                return (option.city + ", " + option.name + " (" + option.airport_code + ")");
            }}
            onInputChange={inputChange}
            onChange={((event, value) => {
                changeAirport(value);
            })}
            value={journey_airport}
            noOptionsText={t("flight_groups.no_airport_found")}
            options={airportResults}
            loading={loading}
            className={classes.autoComplete}
            loadingText={t('accommodation.loading')}
            renderInput={(props) => (
                <Tooltip followCursor title={journey_airport !== undefined && journey_airport !== null && journey_airport !== "" ? journey_airport.city + ", " + journey_airport.name + " (" + journey_airport.airport_code + ")" : ""} placement="top">
                    <TextField
                        {...props}
                        label={label}
                        InputProps={{
                            ...props.InputProps,
                            className: `${classes.textFieldInput} ${origin_or_dest === "origin" && (isHandHeld ? classes.withFullBorder : classes.withBorder)} ${origin_or_dest === "destination" && (isHandHeld ? classes.withFullBorder : classes.noBorder)}`,
                            startAdornment: (
                                <InputAdornment className={classes.startAdornment}>
                                    { origin_or_dest === "origin" && <FlightTakeoffIcon /> }
                                    { origin_or_dest === "destination" && <FlightLandIcon />}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment className={classes.endAdornment}>
                                    {loading ? <CircularProgress color="inherit" sx={{width: '20px !important', height: 'unset !important'}} /> : null}
                                    {props.InputProps.endAdornment}
                                </InputAdornment>
                            )
                        }}
                        inputProps={{
                            ...props.inputProps,
                            // disable autocomplete and autofill
                            autoComplete: 'off'
                        }}
                        placeholder={t("flight_groups.select_airport")}
                        variant="outlined"
                        size="small"
                    />
                </Tooltip>
            )}
        />
    );
};

export default React.memo(AirportSearch);
