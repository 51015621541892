import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    CircularProgress,
    Modal,
    Skeleton,
    styled,
    Tooltip
} from "@mui/material";
import { FlightLand, Info, TwoWheeler } from "@mui/icons-material";
import { flatten, uniqBy } from "lodash";
import { useSnackbar } from "notistack";
import { getPlaneStartDate } from "./utils/getPlaneStartDate";
import { getPlaneEndDate } from "./utils/getPlaneEndDate";
import { StepsDirectionsManager } from "./utils/stepsDirectionsManager";
import { computeTransportDuration } from "./utils/computeTransportDuration";
import { computeDaysCountForPlane } from "./utils/computeDaysCountForPlane";
import { transportVehicles } from "./utils/selectStepTransport";
import { timeStringToSeconds } from "./utils/timeStringToSeconds";
import { Transport } from "./objects/transport";
import { Place, Route } from "./objects/r2rSearchResponse";
import GetVehiclesColor from "./Functions/GetVehiclesColor";
import { PlaneOperatingDays } from "./objects/planeOperatingDays";
import { ItineraryInput } from "./objects/itineraryState";
import { VehicleKind } from "./objects/itinerary";
import "../../Style/TransportPanel.css";

type Props = {
    a: ItineraryInput,
    b: ItineraryInput,
    loading: boolean,
    transports: Transport[],
    containerRef: React.RefObject<HTMLDivElement>,
    onChooseTransport: (
        transport: Transport['alternatives'][number],
        r2rPlaces: Place[],
        r2rRoutes: Route[],
        isCustom: boolean,
    ) => void,
    onClose: () => void,
}

export function ItineraryTransportChooseModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [showAlternativesFor, setShowAlternativesFor] = useState<Transport | null>(null);
    const [showManualForm, setShowManualForm] = useState(false);
    const loading = props.loading;

    const onGoBack = () => {
        if (showManualForm) {
            setShowManualForm(false);
        } else if (showAlternativesFor) {
            setShowAlternativesFor(null);
        } else {
            props.onClose();
        }
    };

    const onClick = (transport: Transport) => {
        if (transport.types.includes('plane')) {
            setShowAlternativesFor(transport);
        } else if (transport.alternatives[0]) {
            props.onChooseTransport(
                transport.alternatives[0],
                transport.r2rPlaces,
                transport.r2rRoutes,
                false
            );
        }
    };

    return (
        <Modal
            open
            container={() => props.containerRef.current}
            sx={{
                position: 'absolute',
                height: '100%'
            }}
            components={{ Backdrop }}
            disablePortal
        >
            <Box
                className="transport-panel-bg active"
                sx={{
                    top: 0,
                    right: 0,
                    left: 'unset',
                    width: '80%',
                    minWidth: 700,
                    height: '100%',
                    overflow: 'auto'
                }}
            >
                <Box className={"inside-panel-wrapper "} sx={{ paddingLeft: 0 }}>
                    {
                        !showManualForm &&
                        <div>
                            <div className={"inside-panel-header"} > 
                                <i
                                    className={"fa fa-long-arrow-left left-arrow-transport-mode"}
                                    onClick={onGoBack}
                                />
                                <span className={"title-transport-panel block-center text-center"} >
                                    {t("itinerary.transport_choose")}
                                </span>
                            </div>
                            <Box className={"inside-panel-container"} sx={{ width: '100%', height: 'calc(100% - 35px)' }}>
                                {
                                    loading &&
                                    <Loading />
                                }
                                {
                                    !loading && !showAlternativesFor && props.transports.map((transport, index) => {
                                        return (
                                            <div key={index} className={"transport-suggestion-container"}>
                                                <div className={"itinerary-transport-mode-name"}>
                                                    {
                                                        !transport.types.includes('plane') &&
                                                        t(`global.${transport.types[0]}`)
                                                    }
                                                    {
                                                        transport.types.includes('plane') &&
                                                        `${t('global.plane')} ${transport.origin.name} à ${transport.destination.name}`
                                                    }
                                                </div>
                                                <div className={"itinerary-transport-mode-duration"}>
                                                    {
                                                        transport.alternatives[0] ?
                                                            secondsToTime(
                                                                computeTransportDuration(transport.alternatives[0]),
                                                                true
                                                            ) :
                                                            '00h00'
                                                    }
                                                    {
                                                        transport.types.includes('plane') &&
                                                        ` (${transport.origin.identifier} - ${transport.destination.identifier})`
                                                    }
                                                </div>
                                                <i className={"itinerary-transport-mode-more-info fa fa-chevron-right"}/>
                                                <div className={"itinerary-transport-mode-price ft-orange"}>
                                                    {t("itinerary.select_transport")}
                                                </div>
                                                {
                                                    transport.types.map((type, index) => (
                                                        <img
                                                            key={index}
                                                            src={"/Img/transport/route-" + type + ".png"}
                                                            height="25px"
                                                            className={"itinerary-transport-mode-icon"}
                                                        />
                                                    ))
                                                }
                                                <div className={"itinerary-transport-mode-click-layer"} onClick={() => onClick(transport)} />
                                            </div>
                                        );
                                    })
                                }
                                {
                                    !loading && showAlternativesFor &&
                                    <Alternatives
                                        transport={showAlternativesFor}
                                        onChoose={(...args) => props.onChooseTransport(...args, false)}
                                    />
                                }
                                {
                                    !loading && !showAlternativesFor &&
                                    <div className={"transport-suggestion-container"} onClick={() => setShowManualForm(true)}>
                                        <div className={"itinerary-transport-mode-name"}>
                                            {t("itinerary.add_manual_transport")}
                                        </div>
                                        <i className={"itinerary-transport-mode-more-info fa fa-chevron-right"}/>
                                        <div className={"itinerary-transport-mode-price ft-orange"}>
                                            {t("itinerary.select_transport")}
                                        </div>
                                        <div className={"itinerary-transport-mode-click-layer"}/>
                                    </div>
                                }
                            </Box>
                        </div>
                    }
                    {
                        showManualForm &&
                        <ManualTransportForm
                            a={props.a}
                            b={props.b}
                            onGoBack={onGoBack}
                            onValidate={(...args) => props.onChooseTransport(...args, true)}
                        />
                    }
                </Box>
            </Box>
        </Modal>
    );
}

type AlternativesProps = {
    transport: Transport,
    onChoose: (
        transportAlternative: Transport['alternatives'][number],
        r2rPlaces: Place[],
        r2rRoutes: Route[]
    ) => void
}

function Alternatives(props: AlternativesProps): JSX.Element {
    const { t } = useTranslation();
    const days: {no: number, name: string}[] = uniqBy(
        new Array(7).fill(null).map((_, index) => {
            const no = parseInt(t(`itinerary.operating-days[${index}].no`));
            const name = t(`itinerary.operating-days[${index}].name`);
            return { no, name };
        }),
        (item) => item.no
    );

    return (
        <>
            {
                props.transport.alternatives.map((transport, index) => {
                    const airSteps = transport.steps.filter((item) => {
                        return item.vehicle.kind === 'plane';
                    });
                    const daysCount = computeDaysCountForPlane(transport);
                    const planeStartDate = getPlaneStartDate(
                        transport,
                        window.moment.utc().toISOString()
                    );
                    const planeEndDate = getPlaneEndDate(
                        transport,
                        window.moment.utc().toISOString()
                    );

                    return (
                        <Box
                            key={index}
                            className={"transport-suggestion-container"}
                            sx={{
                                "backgroundColor": transport.disabled ?
                                    '#f5676e' :
                                    undefined,
                                '&:not(:hover) *': {
                                    color: transport.disabled ?
                                        '#fff !important' :
                                        undefined
                                }
                            }}
                        >
                            <Box className={"itinerary-transport-mode-name"} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <>
                                    {
                                        secondsToTime(
                                            planeStartDate.get('hours') * 3600 +
                                            planeStartDate.get('minutes') * 60 +
                                            planeStartDate.get('seconds')
                                        )
                                    }
                                    <FlightLand fontSize="inherit" sx={{ fontSize: 14 }} />
                                    {
                                        secondsToTime(
                                            planeEndDate.get('hours') * 3600 +
                                            planeEndDate.get('minutes') * 60 +
                                            planeEndDate.get('seconds')
                                        )
                                    }{' '}
                                    (
                                    {
                                        daysCount === 0 &&
                                        t('itinerary.arrive-same-day')
                                    }
                                    {
                                        daysCount === 1 &&
                                        t('itinerary.arrive-the-next-day')
                                    }
                                    {
                                        daysCount > 1 &&
                                        t('itinerary.arrive-after', { count: daysCount })
                                    }
                                    )
                                </>
                            </Box>
                            <div className={"itinerary-transport-mode-duration"}>
                                {
                                    days.filter(({ no }) => {
                                        return transport.operatingDays.includes(no);
                                    }).map(({ name }) => {
                                        return name;
                                    }).join(', ')
                                }
                            </div>
                            {
                                !transport.disabled &&
                                <>
                                    <i className={"itinerary-transport-mode-more-info fa fa-chevron-right"}/>
                                    <div className={"itinerary-transport-mode-price ft-orange"}>
                                        {t("itinerary.select_transport")}
                                    </div>
                                </>
                            }
                            {
                                transport.disabled &&
                                <Tooltip title={t('itinerary.flight-departure-date-error')}>
                                    <Info className="itinerary-transport-mode-more-info" sx={{ marginRight: 2.5 }} />
                                </Tooltip>
                            }
                            <div className={"itinerary-transport-mode-icon"}>
                                {
                                    secondsToTime(
                                        computeTransportDuration(transport),
                                        true
                                    )
                                }
                                {' '}({airSteps.length === 1 ? 'direct' : `${airSteps.length - 1} stop`})
                            </div>
                            {
                                !transport.disabled &&
                                <div
                                    className={"itinerary-transport-mode-click-layer"}
                                    onClick={() => props.onChoose(transport, props.transport.r2rPlaces, props.transport.r2rRoutes)}
                                />
                            }
                        </Box>
                    );
                })
            }
        </>
    );
}

type ManualTransportFormProps = {
    a: ItineraryInput,
    b: ItineraryInput,
    onGoBack: () => void,
    onValidate: (
        transport: Transport['alternatives'][number],
        r2rPlaces: Place[],
        r2rRoutes: Route[]
    ) => void
}

function ManualTransportForm(props: ManualTransportFormProps): JSX.Element {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [openTypeSelector, setOpenTypeSelector] = useState(false);
    const [type, setType] = useState<VehicleKind>('car');
    const [departureTime, setDepartureTime] = useState('09:00');
    const [durationHour, setDurationHour] = useState('0');
    const [durationMinute, setDurationMinute] = useState('10');
    const [distance, setDistance] = useState('0');
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState<'auto' | 'manual'>('auto');

    const onValidate = async () => {
        setLoading(true);
        const manager = StepsDirectionsManager.getInstance();
        let path: google.maps.LatLng[] = [];

        const realMode = !['plane', 'helicopter'].includes(type) ?
            mode :
            'manual';
        let realDuration = 0;
        let realDistance = 0;

        const result = await manager.findRoutesBetween({
            mode: google.maps.TravelMode.DRIVING,
            a: !props.a.destination ?
                props.a.places_id :
                props.a.destination,
            b: !props.b.destination ?
                props.b.places_id :
                props.b.destination,
            departureTime: window.moment.utc(props.a.end_date).toDate(),
            avoidFerries: false,
            provideRouteAlternatives: true,
            service: 'google-maps'
        });

        if (realMode === 'auto') {
            if ((result?.routes[0]?.legs[0]?.steps.length ?? 0) <= 0) {
                enqueueSnackbar(
                    'Impossible de trouver un trajet automatiquement',
                    { variant: 'error' }
                );
                setLoading(false);
                return;
            }
            realDuration = result?.routes[0]?.legs[0]?.steps.reduce((prev, current) => {
                return prev + (current.duration?.value ?? 0);
            }, 0) ?? 0;
            realDistance = result?.routes[0]?.legs[0]?.steps.reduce((prev, current) => {
                return prev + (current.distance?.value ?? 0);
            }, 0) ?? 0;
        } else {
            realDuration = parseFloat(durationHour) * 3600 +
                parseFloat(durationMinute) * 60;
            realDistance = parseFloat(distance) * 1000;
        }

        path = flatten(
            result?.routes[0]?.legs[0]?.steps.map((step) => {
                return step.path;
            })
        );

        if (path.length === 0) {
            path = [
                await manager.transformStepToCoordinates(props.a),
                await manager.transformStepToCoordinates(props.b),
            ];
        }

        realDuration = Math.floor(realDuration);
        realDistance = Math.floor(realDistance);

        props.onValidate(
            {
                disabled: false,
                operatingDays: [
                    PlaneOperatingDays.SUNDAY,
                    PlaneOperatingDays.MONDAY,
                    PlaneOperatingDays.TUESDAY,
                    PlaneOperatingDays.WEDNESDAY,
                    PlaneOperatingDays.THURSDAY,
                    PlaneOperatingDays.FRIDAY,
                    PlaneOperatingDays.SATURDAY
                ],
                steps: [
                    {
                        vehicle: transportVehicles.find((item) => {
                            return item.kind === type;
                        })!,
                        path: path,
                        departureTime: timeStringToSeconds(departureTime),
                        arrivalTime: timeStringToSeconds(departureTime) + realDuration,
                        distance: realDistance,
                        duration: realDuration,
                        arrPlace: -1,
                        depPlace: -1
                    }
                ]
            },
            [],
            []
        );
        setLoading(false);
    };

    return (
        <div>
            <div className={"inside-panel-header"}>
                <i className={"fa fa-long-arrow-left left-arrow-transport-mode"} onClick={props.onGoBack}/>
                <span className={"title-transport-panel block-center text-center"} >{t("itinerary.add_manual_transport")}</span>
            </div>
            <Box className={"inside-panel-container"} sx={{ width: '100%' }}>
                <div className={ "manual-transport-type-container" }>
                    <div>
                        <label>{ t("itinerary.choose_vehicle_type") }</label>
                    </div>
                    <div className={ "manual-transport-type" } onClick={() => setOpenTypeSelector((state) => !state)}>
                        <span>
                            {
                                type !== 'bike' &&
                                <i
                                    className={
                                        "itinerary-sortable-transport-rome2rio-icon-vehicle icon-" +
                                        type + " " +
                                        GetVehiclesColor(type)
                                    }
                                />
                            }
                            {
                                type === 'bike' &&
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        color: '#F7BF45',
                                        border: '2px solid #F7BF45',
                                        left: 5,
                                        fontSize: 16,
                                        borderRadius: '50%',
                                        width: 24,
                                        height: 24,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TwoWheeler fontSize="inherit" color="inherit" />
                                </Box>
                            }
                            { t("global." + type) }
                        </span>
                        <div className={ `manual-transport-type-option-container-${(openTypeSelector ? "open" : "close")}` }>
                            <p className={ "manual-transport-type-option" } onClick={() => {
                                setType("plane"); 
                            }}><i className={ "itinerary-sortable-transport-rome2rio-icon-vehicle icon-plane green" }/> { t("global.plane") }</p>
                            <p className={ "manual-transport-type-option" } onClick={() => {
                                setType("helicopter"); 
                            }}><i className={ "itinerary-sortable-transport-rome2rio-icon-vehicle icon-helicopter light-blue" }/> { t("global.helicopter") }</p>
                            <p className={ "manual-transport-type-option" } onClick={() => {
                                setType("car"); 
                            }}><i className={ "itinerary-sortable-transport-rome2rio-icon-vehicle icon-car gray" }/> { t("global.car") }</p>
                            <p className={ "manual-transport-type-option" } onClick={() => {
                                setType("bus"); 
                            }}><i className={ "itinerary-sortable-transport-rome2rio-icon-vehicle icon-bus orange" }/> { t("global.bus") }</p>
                            <p className={ "manual-transport-type-option" } onClick={() => {
                                setType("taxi"); 
                            }}><i className={ "itinerary-sortable-transport-rome2rio-icon-vehicle icon-taxi gray" }/> { t("global.taxi") }</p>
                            <p className={ "manual-transport-type-option" } onClick={() => {
                                setType("train"); 
                            }}><i className={ "itinerary-sortable-transport-rome2rio-icon-vehicle icon-train purple" }/> { t("global.train") }</p>
                            <p className={ "manual-transport-type-option" } onClick={() => {
                                setType("foot"); 
                            }}><i className={ "itinerary-sortable-transport-rome2rio-icon-vehicle icon-foot gray" }/> { t("global.foot") }</p>
                            <p className={ "manual-transport-type-option" } onClick={() => {
                                setType("ferry"); 
                            }}><i className={ "itinerary-sortable-transport-rome2rio-icon-vehicle icon-ferry light-blue" }/> { t("global.ferry") }</p>
                            <p className={ "manual-transport-type-option" } onClick={() => {
                                setType("bicycle"); 
                            }}><i className={ "itinerary-sortable-transport-rome2rio-icon-vehicle icon-bicycle gray" }/> { t("global.bicycle") }</p>
                            <p
                                className={ "manual-transport-type-option" }
                                onClick={() => {
                                    setType("bike"); 
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 5,
                                        left: 10,
                                        color: '#F7BF45',
                                        border: '2px solid #F7BF45',
                                        fontSize: 16,
                                        borderRadius: '50%',
                                        width: 24,
                                        height: 24,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TwoWheeler fontSize="inherit" color="inherit" />
                                </Box>
                                { t("global.bike") }
                            </p>
                            <p className={ "manual-transport-type-option" } onClick={() => {
                                setType("other"); 
                            }}>{ t("global.other") }</p>
                        </div>
                    </div>
                </div>
                <div className={ "manual-transport-time-container" }>
                    <div>
                        <label>{ t("itinerary.choose_vehicle_depart_time") }</label>
                    </div>
                    <input type={ "time" } className={ "manual-transport-time ft-basic-selector ft-mr10" } defaultValue={ "09:00" } onChange={(event) => {
                        setDepartureTime(event.target.value);
                    }}/>
                </div>
                {
                    !['plane', 'helicopter'].includes(type) &&
                    <div className={ "manual-transport-duration-container" }>
                        <div>
                            <label>
                                {t('itinerary.choose-manual-transport-mode')}
                            </label>
                            <ModeSelect value={mode} onChange={(event) => setMode(event.target.value as typeof mode)}>
                                <option value="auto">{t('itinerary.automatic')}</option>
                                <option value="manual">{t('itinerary.manual')}</option>
                            </ModeSelect>
                        </div>
                    </div>
                }
                {
                    (mode === 'manual' || ['plane', 'helicopter'].includes(type)) &&
                    <>
                        <div className={ "manual-transport-duration-container" }>
                            <div>
                                <label>{ t("itinerary.choose_vehicle_duration") }</label>
                            </div>
                            <input type={ "number" } className={ "manual-transport-duration manual-transport-duration-hour ft-basic-selector ft-mr10" } defaultValue={ 0 } onChange={(event) => {
                                setDurationHour(event.target.value);
                            }}/>
                            <span>h</span>
                            <input type={ "number" } className={ "manual-transport-duration manual-transport-duration-minute ft-basic-selector ft-mr10" } defaultValue={ 10 } onChange={(event) => {
                                setDurationMinute(event.target.value);
                            }}/>
                        </div>
                        <div className={ "manual-transport-distance-container" }>
                            <div>
                                <label>{ t("itinerary.choose_vehicle_distance") }</label>
                            </div>
                            <input type={ "number" } className={ "manual-transport-distance ft-basic-selector ft-mr10" } defaultValue={ 10 } onChange={(event) => {
                                setDistance(event.target.value);
                            }}/>
                        </div>
                    </>
                }
                <div className={ "manual-transport-btn-container" }>
                    <ValidateButton
                        className={ "btn-orange" }
                        onClick={onValidate}
                    >
                        { t("global.validate") }
                        {
                            loading &&
                            <CircularProgress sx={{ marginLeft: 2 }} size={20} color="inherit" />
                        }
                    </ValidateButton>
                </div>
            </Box>
        </div>
    );
}

function Loading(): JSX.Element {
    return (
        <Box sx={{ padding: 1.5 }}>
            <Skeleton sx={{ marginBottom: 1 }} variant="rounded" animation="wave" height={50} />
            <Skeleton sx={{ marginBottom: 1 }} variant="rounded" animation="wave" height={50} />
            <Skeleton sx={{ marginBottom: 1 }} variant="rounded" animation="wave" height={50} />
            <Skeleton sx={{ marginBottom: 1 }} variant="rounded" animation="wave" height={50} />
            <Skeleton variant="rounded" animation="wave" height={50} />
        </Box>
    );
}

function Backdrop(): JSX.Element {
    return (
        <Box
            sx={{
                height: '100%',
                backgroundColor: '#000',
                opacity: 0.5
            }}
        />
    );
}

function secondsToTime(seconds: number, format = false): string {
    let h: string | number = Math.floor(seconds / 3600);
    let m: string | number = Math.floor((seconds % 3600) / 60);
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}${format ? 'h' : ':'}${m}`;
}

const ValidateButton = styled('button')(() => ({
    display: 'inline-flex',
    alignItems: 'center'
}));

const ModeSelect = styled('select')(() => ({
    display: 'block',
    background: 'none',
    inset: 0,
    border: '1px solid #ccc',
    borderRadius: '4px',
    color: '#ccc',
    padding: '5px'
}));
