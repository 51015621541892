import axios from "axios";
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../i18n.jsx";

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';

import CloseIcon from '@material-ui/icons/Close';

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie.js";
import CustomAutoComplete from "./CustomAutoComplete";
import SaveFiltersDialog from "./SaveFiltersDialog.js";
import DeleteFiltersDialog from "./DeleteFiltersDialog.js";
import GetUserAgency from "../LeftMenuPanel/Functions/GetUserAgency.js";
import OrderFiltersDialog from "./OrderFiltersDialog.js";

const useStyles = makeStyles((theme) => ({
    formControlRoot: {
        "&& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: "bold",
            color: "black"
        }
    },
    formControlRoot1: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: "bold",
            color: "black"
        }
    },
    formControlRoot2: {
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(20px, 10px) scale(1)',
            fontSize: 12,
            fontWeight: 500,
            color: "black"
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            padding: 5
        },
        "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd": {
            paddingRight: '10px',
            fontSize: 12,
            fontWeight: "bold"
        }
    },
    list: {
        width: 320
    },
    gridTitle: {
        "&.mui-jss-MuiDialogTitle-root": {
            padding: 8,
            paddingLeft: 18
        }
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "black"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontSize12: {
        fontSize: "12px !important"
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    customLabel: {
        "&& .mui-jss-MuiFormControlLabel-label": {
            fontSize: "12px",
            fontWeight: 500,
            whiteSpace: "nowrap"
        }
    },
    customRadio: {
        "&& .mui-jss-MuiSvgIcon-root": {
            height: 16,
            width: 16
        }
    }
}));

const TripListFilters = forwardRef((props, ref) => {
    const { handleOpenDrawer, trip_list_filter } = props;
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const valueDateType = useSelector(store => store.menu.valueDateType);
    const startDate = useSelector(store => store.menu.startDate);
    const endDate = useSelector(store => store.menu.endDate);
    const fileType = useSelector(store => store.menu.fileType);
    const dateType = useSelector(store => store.menu.dateType);
    const tripStatusFilter = useSelector(store => store.menu.tripStatusFilter);
    const userTO = useSelector(store => store.menu.userTO);
    const agencyFilter = useSelector(store => store.menu.agencyFilter);
    const userAgence = useSelector(store => store.menu.userAgence);
    const userAgenceList = useSelector(store => store.menu.userAgenceList);
    const creator = useSelector(store => store.menu.creator);
    const creatorList = useSelector(store => store.menu.creatorList);
    const paxNumber = useSelector(store => store.menu.paxNumber);
    const nightNumber = useSelector(store => store.menu.nightNumber);
    const userList = useSelector(store => store.menu.userList);
    const agencies = useSelector(store => store.menu.agencies);
    const tripInitBy = useSelector(store => store.menu.tripInitBy);
    const tripTags = useSelector(store => store.menu.tripTags);
    const myFiles = useSelector(store => store.menu.myFiles);
    const customFilters = useSelector(store => store.menu.customFilters);
    const customFiltersSelected = useSelector(store => store.menu.customFiltersSelected);
    const completionPercentageFilter = useSelector(store => store.menu.completionPercentageFilter);
    const edit_filter_order = useSelector(store => store.menu.edit_filter_order);
    const user = useSelector(store => store.user.user);

    const [dateError, setDateError] = useState(false);
    const [saveFiltersOpen, setSaveFiltersOpen] = useState(false);
    const [deleteFiltersOpen, setDeleteFiltersOpen] = useState(false);
    const [orderFiltersOpen, setOrderFiltersOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [completionValue, setCompletionValue] = useState(completionPercentageFilter.slice());
    const paxArray = [{ label: "1-2" }, { label: "3-4" }, { label: "5-7" }, { label: "8-10" }, { label: "+10" }];
    const nightArray = [{ label: "1-2" }, { label: "3-4" }, { label: "5-7" }, { label: "8-10" }, { label: "+10" }];
    const initByArray = [{ label: "TO" }, { label: t("menu.trip_list.agency") }];
    const defaultTripTags = [
        { label: "is_paid_balance" },
        { label: "is_no_paid_balance" },
        { label: "is_paid_deposit" },
        { label: "is_signed" },
        { label: "has_invoice" },
        { label: "has_no_invoice" },
        { label: "has_roadbook" },
        { label: "no_roadbook" },
        { label: "has_router" },
        { label: "no_router" },
        { label: "is_processed" },
        { label: "minisite_link_sent" },
        { label: "minisite_link_sent_modified" },
        { label: "minisite_link_amendment" },
        { label: "minisite_not_sent" }
    ];
    let trip_status = [];
    //(quotation_code === "verdie" ?
    //[
    //{label:"ONGOING"},
    //{label: "VALIDATED"},
    //{label: "AGENCY_MOD"},
    //{label: "TO_UPDATED"},
    //{label: "USER_VALID"},
    //{label: "TOBOOK_VERDIE"},
    //{label: "DEPOSIT"},
    //{label: "ONREQUEST"},
    //{label: "BOOKED"},
    //{label: "ADDITEM"},
    //{label: "CLOSED"},
    //{label: "CANCELLED"},
    //{label: "ARCHIVED"},
    //{label: "INPROGRESS"},
    //{label: "FINISHED"}] : (quotation_code === "marcovasco" ?
    //[{label:"ONGOING"}, {label: "VALIDATED"}, {label: "ONREQUEST"}, {label: "BOOKED"}, {label: "ADDITEM"}, {label: "INPROGRESS"}, {label: "FINISHED"}, {label: "CANCELLED"}] :
    //[{label:"ONGOING"}, {label:"VALIDATED"}, {label:"USER_VALID"}, {label:"TOBOOK"}, {label:"ADDITEM"}, {label:"ONREQUEST"}, {label:"BOOKED"}, {label:"CLOSED"}, {label:"CANCELLED"}]));
    if (quotation_code === "verdie") {
        trip_status = [
            {label:"ONGOING"},
            {label: "AGENCY_MOD"},
            {label: "TO_UPDATED"},
            {label: "USER_VALID"},
            {label: "TOBOOK_VERDIE"},
            {label: "ONREQUEST"},
            {label: "BOOKED"},
            {label: "ADDITEM"},
            {label: "CLOSED"},
            {label: "CANCELLED"},
            {label: "ARCHIVED"},
            {label: "INPROGRESS"},
            {label: "FINISHED"}]
    }
    else if (quotation_code === "marcovasco" || quotation_code === "americavoyages" || quotation_code === "tripadekua") {
        trip_status = [{label:"ONGOING"}, {label: "VALIDATED"}, {label: "ONREQUEST"}, {label: "BOOKED"}, {label: "ADDITEM"}, {label: "INPROGRESS"}, {label: "FINISHED"}, {label: "CLOSED"}, {label: "CANCELLED"}];
    }
    else if (quotation_code === "visiteurs" || quotation_code === "beachcomber") {
        trip_status = [{label:"ONGOING"}, {label:"VALIDATED"}, {label:"USER_VALID"}, {label:"TOBOOK"}, {label:"ADDITEM"}, {label:"ONREQUEST"}, {label:"BOOKED"}, {label:"CLOSED"}, {label:"CANCELLED"}, {label: "INPROGRESS"}, {label: "FINISHED"}];
    } else if (quotation_code === "volonline") {
        trip_status = [{label:"ONGOING"}, {label:"VALIDATED"}, {label:"USER_VALID"}, {label:"ADDITEM"}, {label:"TOBOOK"}, {label:"BOOKED"}, {label:"CANCELLED"}];
    } else if (quotation_code === "cercledesvoyages" || quotation_code === "continentsinsolites") {
        trip_status = [
            { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
            { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
            { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CDV" },
            { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
            { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
            { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
            { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" }
        ];
    } else if (quotation_code === "tropicalementvotre") {
        trip_status = [
            { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
            { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION_SENT" },
            { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
            { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
            { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
            { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
            { group: "TRIP_STATUS_MODIFICATION", label: "COUNTEROFFER" },
            { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TO_TRANSMIT" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" }
        ];
    } else if (quotation_code === 'terreslointaines') {
        trip_status = [
            { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
            { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
            { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
            { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
            { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
            { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" }
        ];
    } else if (quotation_code === "touristforum") {
        trip_status = [
            { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
            { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
            { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
            { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
            { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
            { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" },
            { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "NOT_AVAILABLE" }
        ]
    }
    const allSelectedStatus = trip_status.length === tripStatusFilter.length;
    const allSelectedUserTO = ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? userList.length - 1 === userTO.length && userTO.find((user) => user.id !== null) !== undefined : userList.length === userTO.length;
    const allSelectedUserAgence = ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) ? userAgenceList.length - 1 === userAgence.length && userList.find((user) => user.id !== null) !== undefined : userAgenceList.length === userAgence.length;
    const allSelectedCreator = creatorList.length === creator.length;
    const allSelectedPax = paxArray.length === paxNumber.length;
    const allSelectedNight = nightArray.length === nightNumber.length;
    const allInitBy = initByArray.length === tripInitBy.length;
    const allTripTags = defaultTripTags.length === tripTags.length;
    const marks = [
        {
            value: 0,
            label: '0%'
        },
        {
            value: 100,
            label: '100%'
        }
    ];
    useImperativeHandle(ref, () => ({
        handleMyFiles() {
            //dispatch({type: "MENU_TOGGLE_MY_FILES", payload: {checked: !myFiles, id: user.id}});
            handleMyFilesFilters();
        }
    }));
    // useEffect(() => {
    //     if (!['cercledesvoyages'].includes(quotation_code)) {
    //         handleMyFilesFilters();
    //     }
    // }, []);
    useEffect(() => {
        if ((userAgenceList.length !== 0 && userList.length !== 0)) {
            handleMyFilesFilters();
        }
    }, [userAgenceList, userList]);
    useEffect(() => {
        applyFilters();
        // if (['cercledesvoyages'].includes(quotation_code)) {
        // }
    }, [myFiles, userTO, userAgence])
    useEffect(() => {
        setCompletionValue(completionPercentageFilter);
    }, [completionPercentageFilter]);
    useEffect(() => {
        if (agencyFilter !== null) {
            GetUserAgency(agencyFilter, userList, dispatch);
        }
    }, [agencyFilter]);
    useEffect(() => {
        if (moment(startDate).isAfter(moment(endDate))) {
            setDateError(true);
        } else {
            setDateError(false);
        }
    }, [startDate]);
    useEffect(() => {
        if (customFiltersSelected !== null) {
            applyFilters(true);
        }
    }, [customFiltersSelected]);
    const handleMyFilesFilters = () => {
        if (["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines'].includes(quotation_code) && userAgenceList.length !== 0 && userList.length !== 0) {
            if (user.groups.length === 0 || user.groups.some(group => !group.is_admin)) {
                dispatch({ type: "MENU_TOGGLE_MY_FILES_STATUS", payload: { checked: !myFiles, id: user.id } });
            }
        } else {
            dispatch({type: "MENU_TOGGLE_MY_FILES_STATUS_OTHER", payload: { checked: !myFiles, user: user } });
        }
    };
    const handleChangeRadio = (e) => {
        dispatch({
            type: "MENU_SET_VALUE_DATE_TYPE",
            payload: {
                valueDateType: e.target.value
            }
        });
    };
    const handleReset = () => {
        setCompletionValue([0, 0]);
        dispatch({
            type: !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) ? "MENU_RESET_TRIP_FILTER" : "MENU_RESET_TRIP_FILTER_CERCLE"
        });
    };
    const handleFileTypeChange = (e) => {
        dispatch({
            type: "MENU_SET_FILE_TYPE",
            payload: {
                fileType: e.target.value
            }
        });
    };
    const handleDateTypeChange = (e) => {
        dispatch({
            type: "MENU_SET_DATE_TYPE",
            payload: {
                dateType: e.target.value
            }
        });
    };
    const handleStartDate = (date) => {
        dispatch({
            type: "MENU_SET_START_DATE",
            payload: {
                startDate: date
            }
        });
    };
    const handleEndDate = (date) => {
        dispatch({
            type: "MENU_SET_END_DATE",
            payload: {
                endDate: date
            }
        });
    };
    const setTripStatusFilter = (value) => {
        dispatch({
            type: "MENU_SET_TRIP_STATUS_FILTER",
            payload: {
                tripStatusFilter: value
            }
        });
    };
    const setUserAgence = (value) => {
        dispatch({
            type: "MENU_SET_USER_AGENCE",
            payload: {
                userAgence: value
            }
        });
    };
    const setUserTO = (value) => {
        dispatch({
            type: "MENU_SET_USER_TO",
            payload: {
                userTO: value
            }
        });
    };
    const setCreator = (value) => {
        dispatch({
            type: "MENU_SET_CREATOR",
            payload: {
                creator: value
            }
        });
    };
    const setPaxNumber = (value) => {
        dispatch({
            type: "MENU_SET_PAX_NUMBER",
            payload: {
                paxNumber: value
            }
        });
    };
    const setNightNumber = (value) => {
        dispatch({
            type: "MENU_SET_NIGHT_NUMBER",
            payload: {
                nightNumber: value
            }
        });
    };
    const setTripInitBy = (value) => {
        dispatch({
            type: "MENU_SET_TRIP_INIT_BY",
            payload: {
                tripInitBy: value
            }
        });
    };
    const setTripTags = (value) => {
        dispatch({
            type: "MENU_SET_TRIP_TAGS",
            payload: {
                tripTags: value
            }
        });
    };
    const setAgencyFilter = (value) => {
        dispatch({
            type: "MENU_SET_AGENCY_FILTER",
            payload: {
                agencyFilter: value
            }
        });
    };
    const handleSelectAll = (isSelected, type) => {
        if (type === "status") {
            setTripStatusFilter(isSelected ? trip_status : []);
        }
        if (type === "userAgence") {
            let users = userAgenceList.filter((user) => user.id !== null);
            setUserAgence(isSelected ? users : []);
        }
        if (type === "userTO") {
            let users = userList.filter((user) => user.id !== null);
            setUserTO(isSelected ? users : []);
        }
        if (type === "creator") {
            setCreator(isSelected ? creatorList : []);
        }
        if (type === "pax") {
            setPaxNumber(isSelected ? paxArray : []);
        }
        if (type === "night") {
            setNightNumber(isSelected ? nightArray : []);
        }
        if (type === "userAgenceTO") {
            let users = userList.filter((user) => user.id !== null);
            setUserAgence(isSelected ? users : []);
        }
        if (type === "tripInitBy") {
            setTripInitBy(isSelected ? initByArray : []);
        }
        if (type === "tripTags") {
            setTripTags(isSelected ? defaultTripTags : []);
        }
    };
    const getDates = () => {
        if (dateType === "custom") {
            return ({ start_date: startDate, end_date: endDate });
        }

        if (dateType === "current_week") {
            return ({ start_date: moment().clone().startOf('isoWeek'), end_date: moment().clone().endOf('isoWeek') });
        }
        if (dateType === "last_week") {
            return ({ start_date: moment().clone().subtract(1, 'weeks').startOf('isoWeek'), end_date: moment().clone().subtract(1, 'weeks').endOf('isoWeek') });
        }
        if (dateType === "current_month") {
            return ({ start_date: moment().clone().startOf('month'), end_date: moment().clone().endOf('month') });
        }
        if (dateType === "last_month") {
            return ({ start_date: moment().clone().subtract(1, 'months').startOf('month'), end_date: moment().clone().subtract(1, 'months').endOf('month') });
        }
        if (dateType === "last_30") {
            return ({ start_date: moment().clone().subtract(30, 'days'), end_date: moment() });
        }
        if (dateType === "last_90") {
            return ({ start_date: moment().clone().subtract(90, 'days'), end_date: moment() });
        }
        if (dateType === "this_year") {
            return ({ start_date: moment().clone().startOf('year'), end_date: moment().clone().endOf('year') });
        }
    };
    const getOptionLabel = (option, type) => {
        if (type === "agency" || type === "customFilters") {
            return `${option.label}`;
        }
    };
    const applyFilters = (customFilterFlag) => {
        let filter_value = "";
        if (valueDateType !== "") {
            let tmp_dates = getDates();
            if (valueDateType === "creation") {
                filter_value += "&created_date__gte=" + moment(tmp_dates.start_date).format("YYYY-MM-DD") + "T00:00:00Z" + "&created_date__lte=" + moment(tmp_dates.end_date).format("YYYY-MM-DD") + "T23:59:59Z";
            }
            if (valueDateType === "trip") {
                filter_value += "&start_date__gte=" + moment(tmp_dates.start_date).format("YYYY-MM-DD") + "T00:00:00Z" + "&start_date__lte=" + moment(tmp_dates.end_date).format("YYYY-MM-DD") + "T23:59:59Z";
            }
            if (valueDateType === "confirmation") {
                if (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code)) {
                    filter_value += "&confirmation_date__gte=" + moment(tmp_dates.start_date).format("YYYY-MM-DD") + "T00:00:00Z" + "&confirmation_date__lte=" + moment(tmp_dates.end_date).format("YYYY-MM-DD") + "T23:59:59Z";
                } else {
                    filter_value += "&status_contract_confirmed_date__gte=" + moment(tmp_dates.start_date).format("YYYY-MM-DD") + "T00:00:00Z" + "&status_contract_confirmed_date__lte=" + moment(tmp_dates.end_date).format("YYYY-MM-DD") + "T23:59:59Z";
                }
            }
            if (valueDateType === "edit") {
                filter_value += "&modified_date__gte=" + moment(tmp_dates.start_date).format("YYYY-MM-DD") + "T00:00:00Z" + "&modified_date__lte=" + moment(tmp_dates.end_date).format("YYYY-MM-DD") + "T23:59:59Z";
            }
            if (valueDateType === "deadline") {
                filter_value += "&due_date__gte=" + moment(tmp_dates.start_date).format("YYYY-MM-DD") + "T00:00:00Z" + "&due_date__lte=" + moment(tmp_dates.end_date).format("YYYY-MM-DD") + "T23:59:59Z";
            }
            if (valueDateType === "minisite_link_sent_date") {
                filter_value += "&minisite_link_sent_date__gte=" + moment(tmp_dates.start_date).format("YYYY-MM-DD") + "T00:00:00Z" + "&minisite_link_sent_date__lte=" + moment(tmp_dates.end_date).format("YYYY-MM-DD") + "T23:59:59Z";
            }
            if (valueDateType === "minisite_link_sent_modified_date") {
                filter_value += "&minisite_link_sent_modified_date__gte=" + moment(tmp_dates.start_date).format("YYYY-MM-DD") + "T00:00:00Z" + "&minisite_link_sent_modified_date__lte=" + moment(tmp_dates.end_date).format("YYYY-MM-DD") + "T23:59:59Z";
            }
            if (valueDateType === "minisite_link_amendment_date") {
                filter_value += "&minisite_link_amendment__gte=" + moment(tmp_dates.start_date).format("YYYY-MM-DD") + "T00:00:00Z" + "&minisite_link_amendment__lte=" + moment(tmp_dates.end_date).format("YYYY-MM-DD") + "T23:59:59Z";
            }
        }
        if (tripStatusFilter.length !== 0) {
            if (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code)) {
                let tmp = "";
                for (let i = 0; i < tripStatusFilter.length; i++) {
                    if (i === 0) {
                        tmp += tripStatusFilter[i].label;
                    } else {
                        tmp += "," + tripStatusFilter[i].label;
                    }
                }
                filter_value += "&current_version__status__in=" + tmp;
            } else {
                let status_contract = "";
                let status_modification = "";
                let status_booking_progress = "";
                tripStatusFilter.map((status) => {
                    if (status.group === "TRIP_STATUS_CONTRACT") {
                        status_contract += status_contract !== "" ? "," + status.label : status.label;
                    }
                    if (status.group === "TRIP_STATUS_MODIFICATION") {
                        status_modification += status_modification !== "" ? "," + status.label : status.label;
                    }
                    if (status.group === "TRIP_STATUS_BOOKING_PROGRESS") {
                        status_booking_progress += status_booking_progress !== "" ? "," + status.label : status.label;
                    }
                });
                if (status_contract !== "") {
                    filter_value += "&current_version__status_contract__in=" + status_contract;
                }
                if (status_modification !== "") {
                    filter_value += "&current_version__status_modification__in=" + status_modification;
                }
                if (status_booking_progress !== "") {
                    filter_value += "&current_version__status_booking_progress__in=" + status_booking_progress;
                }
            }
        }
        if (userTO.length !== 0) {
            if (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code)) {
                let tmp = "";
                for (let i = 0; i < userTO.length; i++) {
                    if (i === 0) {
                        tmp += userTO[i].id;
                    } else {
                        tmp += "," + userTO[i].id;
                    }
                }
                filter_value += "&user_to_id__in=" + tmp;
            } else {
                let tmp = [];
                for (let i = 0; i < userTO.length; i++) {
                    if (userTO[i].id !== null) {
                        tmp.push(userTO[i].id);
                    } else {
                        filter_value += "&user_to__isnull=true";
                    }
                }
                if (tmp.length !== 0) {
                    filter_value += "&user_to_id__in=" + tmp.join();
                }
            }
        }
        if (userAgence.length !== 0) {
            if (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code)) {
                let tmp = [];
                userAgence.forEach(user => {
                    tmp.push(user.id);
                });
                filter_value += "&user_ta_id__in=" + tmp.join();
            } else {
                let tmp = [];
                for (let i = 0; i < userAgence.length; i++) {
                    if (userAgence[i].id !== null) {
                        tmp.push(userAgence[i].id);
                    } else {
                        filter_value += "&user_ta__isnull=true";
                    }
                }
                if (tmp.length !== 0) {
                    filter_value += "&user_ta_id__in=" + tmp.join();
                }
            }
        }
        if (creatorList.length !== 0) {
            let tmp = "";
            for (let i = 0; i < creator.length; i++) {
                if (i === 0) {
                    tmp += creator[i].id;
                } else {
                    tmp += "," + creator[i].id;
                }
            }
            filter_value += "&creator_id__in=" + tmp;
        }
        if (paxNumber.length !== 0) {
            let sortPax = paxNumber.sort((a, b) => {
                let indexA = paxArray.findIndex(element => element.label === a.label);
                let indexB = paxArray.findIndex(element => element.label === b.label);
                return indexA - indexB;
            });
            let filter1 = sortPax.filter(el => el.label === "+10");
            let filter2 = sortPax.filter(el => el.label !== "+10");
            let tmp = "";
            console.log('filter1:', filter1);
            if (filter1.length !== 0) {
                filter_value += "&nb_pax__gte=10";
            }
            if (filter2.length !== 0) {
                for (let i = 0; i < filter2.length; i++) {
                    if (i === 0) {
                        let tmp_split = filter2[i].label.split("-");
                        tmp += tmp_split[0] + ',' + tmp_split[1];
                    } else {
                        let tmp_split = filter2[i].label.split("-");
                        tmp += "," + tmp_split[0] + ',' + tmp_split[1];
                    }
                }
            }
            if (tmp !== "") {
                filter_value += "&nb_pax__in=" + tmp;
            }
        }
        if (nightNumber.length !== 0) {
            let sortNight = nightNumber.sort((a, b) => {
                let indexA = nightArray.findIndex(element => element.label === a.label);
                let indexB = nightArray.findIndex(element => element.label === b.label);
                return indexA - indexB;
            });
            let filter1 = sortNight.filter(el => el.label === "+10");
            let filter2 = sortNight.filter(el => el.label !== "+10");
            let tmp = "";
            if (filter1.length !== 0) {
                filter_value += "&nb_nights__gte=10";
            }
            if (filter2.length !== 0) {
                for (let i = 0; i < filter2.length; i++) {
                    if (i === 0) {
                        let tmp_split = filter2[i].label.split("-");
                        tmp += tmp_split[0] + ',' + tmp_split[1];
                    } else {
                        let tmp_split = filter2[i].label.split("-");
                        tmp += "," + tmp_split[0] + ',' + tmp_split[1];
                    }
                }
            }
            if (tmp !== "") {
                filter_value += "&nb_nights__in=" + tmp;
            }
        }
        if (agencyFilter !== null) {
            filter_value += "&target_client=" + agencyFilter.id;
        }
        if (tripInitBy.length !== 0) {
            let tmp = "";
            if (tripInitBy.length === 1) {
                if (tripInitBy[0].label === "TO") {
                    tmp = "1,3";
                } else {
                    tmp = "2";
                }
            } else {
                tmp = "1,2,3";
            }
            filter_value += "&creator__client__type__in=" + tmp;
        }
        if (completionValue[0] !== completionPercentageFilter[0] || completionValue[1] !== completionPercentageFilter[1]) {
            if (!customFilterFlag) {
                dispatch({
                    type: "MENU_SET_COMPLETION_PERCENTAGE_FILTER",
                    payload: completionValue
                });
                filter_value += "&completion_percentage__gte=" + completionValue[0] + "&completion_percentage__lte=" + completionValue[1];
            } else {
                filter_value += "&completion_percentage__gte=" + completionPercentageFilter[0] + "&completion_percentage__lte=" + completionPercentageFilter[1];
            }
        }
        if (tripTags.length !== 0) {
            tripTags.map((tag) => {
                if (tag.label === "is_paid_balance") {
                    filter_value += "&is_paid=true";
                }
                if (tag.label === "is_no_paid_balance") {
                    filter_value += "&is_paid=false";
                }
                if (tag.label === "is_signed") {
                    filter_value += "&is_signed=true";
                }
                if (tag.label === "has_invoice") {
                    filter_value += "&current_version__has_invoice=true";
                }
                if (tag.label === "has_no_invoice") {
                    filter_value += "&current_version__has_invoice=false";
                }
                if (tag.label === "has_roadbook") {
                    filter_value += "&current_version__has_roadbook=true";
                }
                if (tag.label === "no_roadbook") {
                    filter_value += "&current_version__has_roadbook=false";
                }
                if (tag.label === "has_router") {
                    filter_value += "&current_version__has_router=true";
                }
                if (tag.label === "no_router") {
                    filter_value += "&current_version__has_router=false";
                }
                if (tag.label === "is_processed") {
                    filter_value += "&current_version__is_processed=true";
                }
                if (tag.label === "is_paid_deposit") {
                    filter_value += "&current_version__is_paid=true";
                }
                if (tag.label === "minisite_not_sent") {
                    filter_value += "&minisite_link_sent=false";
                }
                if (tag.label === "minisite_link_amendment") {
                    filter_value += "&minisite_link_amendment=true";
                }
                if (tag.label === "minisite_link_sent") {
                    filter_value += "&minisite_link_sent=true";
                }
                if (tag.label === "minisite_link_sent_modified") {
                    filter_value += "&minisite_link_sent_modified=true";
                }
            });
        }
        console.log('filter_value:', filter_value);
        dispatch({
            type: "MENU_SET_TRIP_LIST_FILTER",
            payload: {
                trip_list_filter: filter_value
                //trip_list_filter_prev: trip_list_filter_prev
            }
        });
    };
    const handleSaveFiltersOpen = () => {
        setSaveFiltersOpen(!saveFiltersOpen);
    };
    const handleDeleteFiltersOpen = () => {
        setDeleteFiltersOpen(!deleteFiltersOpen);
    };
    const handleCustomFiltersChange = (selectedOptions) => {
        handleReset();
        dispatch({
            type: "MENU_SET_CUSTOM_FILTERS_SELECTED",
            payload: {
                selectedOptions: selectedOptions,
                t: t,
                userAgenceList: userAgenceList,
                userList: userList,
                user: user
            }
        });
    };
    const handleDeleteCustomFilter = (event, id) => {
        event.stopPropagation();
        event.preventDefault();
        setDeleteId(id);
        handleDeleteFiltersOpen();
    };
    const handleCompletionPercentage = (event, newValue) => {
        setCompletionValue(newValue);
    };
    const handleMyFilesChange = (event) => {
        {
            dispatch({
                type: "MENU_TOGGLE_MY_FILES",
                payload: {
                    checked: event.target.checked,
                    id: user.id
                }
            });
        }
    };
    const handleEditFiltersOrder = () => {
        setOrderFiltersOpen(!orderFiltersOpen);
    };
    const valueLabelComponent = (props) => {
        const { children, open, value } = props;
        return (
            <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
                {children}
            </Tooltip>
        );
    };
    return (
        <div className={`${ !downDesktop ? classes.list : ""}`} style={{ overflowY: "hidden" }}>
            <DialogTitle onClose={ handleOpenDrawer } className={classes.gridTitle}>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    <Grid item>
                        <IconButton edge={ "start" } onClick={ handleOpenDrawer }><CloseIcon/></IconButton>
                        <span style={{ fontWeight: "bold" }}>
                            {t("accommodation_list.filters")}
                        </span>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Divider/>
            <DialogContent style={{ maxHeight: "85%" }}>
                {
                    customFilters.length > 0 && (
                        <Fragment>
                            <Grid container direction={"column"} style={{ marginTop: 10, marginBottom: 10 }} spacing={1}>
                                <Grid item xs={12} style={{ fontWeight: "bold" }}>
                                    {t("menu.user_management.custom_filters")}
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={customFilters}
                                        size={"small"}
                                        onChange={(event, selectedOptions) => {
                                            handleCustomFiltersChange(selectedOptions);
                                        }}
                                        renderOption={(option, { selected }) => {
                                            return (
                                                <div style={{ position: "relative", width: "100%" }}>
                                                    {option.name}
                                                    {
                                                        (option.filters_type === "user" || ((quotation_code === 'cercledesvoyages' && user.groups.some(group => group.is_admin === true)) && user.client_full.type !== 2)) && (
                                                            <CloseIcon onClick={(event) => {
                                                                handleDeleteCustomFilter(event, option.id);
                                                            }} fontSize={"small"} style={{ position: "absolute", right: 0 }}/>
                                                        )
                                                    }
                                                </div>
                                            );
                                        }}
                                        getOptionLabel={(option) => getOptionLabel(option, "customFilters")}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        value={customFiltersSelected}
                                        renderInput={(params) => (
                                            <TextField {...params} className={`${classes.formControlRoot2}`} variant={"outlined"} label={t("menu.user_management.custom_filters")} />
                                        )}
                                    />
                                </Grid>
                                {
                                    //["cercledesvoyages"].includes(quotation_code) && customFilters.some(filter => filter.filters_type === 'company') && (
                                    ["cercledesvoyages"].includes(quotation_code) && user.groups.some(group => group.is_admin === true) && customFilters.some(filter => filter.filters_type === 'company') && (
                                        <Grid item xs={12}>
                                            <Button variant={"outlined"} color={"primary"} fullWidth className={`${classes.fontSize12} ${classes.noWrap}`} onClick={handleEditFiltersOrder} >{t('menu.user_management.order_filters')}</Button>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <Divider/>
                        </Fragment>
                    )
                }
                <Grid container justify={ "space-between" } alignItems={ "center" } style={{ marginTop: customFilters.length > 0 ? 10 : 0, marginBottom: customFilters.length > 0 ? 10 : 0 }} >
                    <Grid item style={{ fontWeight: "bold" }}>
                        {t("global.period")}
                    </Grid>
                    <Grid item>
                        <RadioGroup row value={valueDateType} onChange={handleChangeRadio} className={`${classes.genericText}`}>
                            <Grid container direction={"row"}>
                                <Grid item xs={6}>
                                    <FormControlLabel className={classes.customLabel} value={"creation"} control={<Radio color="primary" className={classes.customRadio} />} label={t('menu.trip_list.creation_date')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel className={classes.customLabel} value={"trip"} control={<Radio color="primary" className={classes.customRadio} />} label={t('menu.trip_list.trip_date')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel className={classes.customLabel} value={"deadline"} control={<Radio color="primary" className={classes.customRadio} />} label={t('menu.trip_list.due_date')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel className={classes.customLabel} value={"edit"} control={<Radio color="primary" className={classes.customRadio} />} label={t('menu.invoice.modified-date')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel className={classes.customLabel} value={"confirmation"} control={<Radio color="primary" className={classes.customRadio} />} label={t('menu.trip_list.confirmation_date')} />
                                </Grid>
                                {
                                    ['cercledesvoyages'].includes(quotation_code) && (
                                        <Fragment>
                                            <Grid item xs={12}>
                                                <FormControlLabel className={classes.customLabel} value={"minisite_link_sent_date"} control={<Radio color="primary" className={classes.customRadio} />} label={t('menu.trip_list.minisite_link_sent_date')} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel className={classes.customLabel} value={"minisite_link_sent_modified_date"} control={<Radio color="primary" className={classes.customRadio} />} label={t('menu.trip_list.minisite_link_sent_modified_date')} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel className={classes.customLabel} value={"minisite_link_amendment_date"} control={<Radio color="primary" className={classes.customRadio} />} label={t('menu.trip_list.minisite_link_amendment_date')} />
                                            </Grid>
                                        </Fragment>
                                    )
                                }
                            </Grid>
                        </RadioGroup>
                    </Grid>
                    {
                        valueDateType !== "" && (
                            <Select value={dateType} MenuProps={{ disableScrollLock: true }} className={classes.formControlRoot1} onChange={handleDateTypeChange} variant={"outlined"} style={{ width: "100%" }}>
                                <MenuItem value={"current_week"}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                        {t("menu.trip_list.current_week")}
                                    </Grid>
                                </MenuItem>
                                <MenuItem value={"last_week"}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                        {t("menu.trip_list.last_week")}
                                    </Grid>
                                </MenuItem>
                                <MenuItem value={"current_month"}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                        {t("menu.trip_list.current_month")}
                                    </Grid>
                                </MenuItem>
                                <MenuItem value={"last_month"}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                        {t("menu.trip_list.last_month")}
                                    </Grid>
                                </MenuItem>
                                <Divider/>
                                <MenuItem value={"last_30"}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                        {t("menu.trip_list.last_30")}
                                    </Grid>
                                </MenuItem>
                                <MenuItem value={"last_90"}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                        {t("menu.trip_list.last_90")}
                                    </Grid>
                                </MenuItem>
                                <MenuItem value={"this_year"}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                        {t("menu.trip_list.this_year")}
                                    </Grid>
                                </MenuItem>
                                <Divider/>
                                <MenuItem value={"custom"}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                        {t("menu.trip_list.custom_period")}
                                    </Grid>
                                </MenuItem>
                            </Select>
                        )
                    }
                    {
                        valueDateType !== "" && dateType === "custom" && (
                            <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ i18n.language }>
                                <Grid item container spacing={1} style={{ marginTop: 10 }}>
                                    <Grid item style={{ width: "100%" }}>
                                        <DatePicker
                                            label={t("header.responsive.start")}
                                            value={startDate}
                                            onChange={(newValue) => handleStartDate(newValue)}
                                            //maxDate={endDate}
                                            views={["year", "month", "date"]}
                                            renderInput={(props) =>
                                                (<TextField
                                                    {...props}
                                                    label={t("header.responsive.start")}
                                                    variant={"outlined"}
                                                    className={classes.formControlRoot}
                                                    helperText={""}
                                                    error={false}
                                                    fullWidth={true}
                                                />)
                                            }
                                        />
                                    </Grid>
                                    <Grid item style={{ width: "100%" }}>
                                        <DatePicker
                                            label={t("header.responsive.end")}
                                            value={endDate}
                                            onChange={(newValue) => handleEndDate(newValue)}
                                            minDate={startDate}
                                            views={["year", "month", "date"]}
                                            renderInput={(props) =>
                                                (<TextField
                                                    {...props}
                                                    label={t("header.responsive.end")}
                                                    variant={"outlined"}
                                                    helperText={dateError ? t("cart.wrong_dates1") : ""}
                                                    className={classes.formControlRoot}
                                                    error={dateError}
                                                    fullWidth={true}
                                                />)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        )
                    }
                </Grid>
                <Divider/>
                <Grid container direction={"column"} style={{ marginTop: 10, marginBottom: 10 }} spacing={1}>
                    <Grid item style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                        {t("global.file")}
                    </Grid>
                    {
                        // ["cercledesvoyages"].includes(quotation_code)  && (
                        // ["cercledesvoyages"].includes(quotation_code) && user.groups.some(group => group.is_admin === true) && (
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox size={"small"} checked={myFiles} onChange={handleMyFilesChange} name={"my-files"} />}
                                    label={t("global.my_files")}
                                />
                            </Grid>
                        // )
                    }
                    <Grid item>
                        <CustomAutoComplete
                            options={trip_status}
                            values={tripStatusFilter}
                            type={"status"}
                            allSelected={allSelectedStatus}
                            customSetter={setTripStatusFilter}
                            handleSelectAll={handleSelectAll}
                            customLabel={"ALL"}
                            label={t("global.status")}
                        />
                    </Grid>
                    {
                        // !["cercledesvoyages", "continentsinsolites"].includes(quotation_code) && (
                        //     <Grid item>
                        //         <Select value={fileType} MenuProps={{ disableScrollLock: true }} onChange={handleFileTypeChange} variant={"outlined"} className={classes.formControlRoot1} style={{ width: "100%" }}>
                        //             <MenuItem value={"ALL"}>
                        //                 <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                        //                     {t("menu.trip_list.all_file_type")}
                        //                 </Grid>
                        //             </MenuItem>
                        //             <MenuItem value={"TO"}>
                        //                 <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                        //                     TO
                        //                 </Grid>
                        //             </MenuItem>
                        //             <MenuItem value={"AGENCY"}>
                        //                 <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                        //                     Agence
                        //                 </Grid>
                        //             </MenuItem>
                        //         </Select>
                        //     </Grid>
                        // )
                    }
                    <Grid item>
                        <CustomAutoComplete
                            options={paxArray}
                            values={paxNumber}
                            type={"pax"}
                            allSelected={allSelectedPax}
                            customSetter={setPaxNumber}
                            handleSelectAll={handleSelectAll}
                            customLabel={t("menu.trip_list.all_pax")}
                            label={t("menu.trip_list.pax")}
                        />
                    </Grid>
                    <Grid item>
                        <CustomAutoComplete
                            options={nightArray}
                            values={nightNumber}
                            type={"night"}
                            allSelected={allSelectedNight}
                            customSetter={setNightNumber}
                            handleSelectAll={handleSelectAll}
                            customLabel={t("menu.trip_list.all_night")}
                            label={t("menu.trip_list.night_number")}
                        />
                    </Grid>
                    {
                        !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) && (
                            <Grid item>
                                <CustomAutoComplete
                                    options={initByArray}
                                    values={tripInitBy}
                                    type={"tripInitBy"}
                                    allSelected={allInitBy}
                                    customSetter={setTripInitBy}
                                    handleSelectAll={handleSelectAll}
                                    customLabel={t("menu.trip_list.to_agency")}
                                    label={t("menu.trip_list.trip_init_by")}
                                />
                            </Grid>
                        )
                    }
                    {
                        ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) && (
                            <Grid item>
                                <CustomAutoComplete
                                    options={defaultTripTags}
                                    values={tripTags}
                                    type={"tripTags"}
                                    allSelected={allTripTags}
                                    customSetter={setTripTags}
                                    handleSelectAll={handleSelectAll}
                                    customLabel={"all_trip_tags"}
                                    label={t("menu.trip_list.trip_tags")}
                                />
                            </Grid>
                        )
                    }
                    <Grid item>
                        <Typography gutterBottom>
                            {t("menu.trip_list.completion_percentage")}
                        </Typography>
                        <Slider
                            ValueLabelComponent={valueLabelComponent}
                            value={completionValue}
                            onChange={handleCompletionPercentage}
                            valueLabelDisplay={"auto"}
                            step={1}
                            min={0}
                            max={100}
                            marks={marks}
                        />
                    </Grid>
                    <Divider/>
                    {
                        user.client_full.type !== 2 &&
                        <Grid container direction={"column"} style={{marginTop: 10, marginBottom: 10}} spacing={1}>
                            <Grid item style={{fontWeight: "bold"}}>
                                {t("menu.user_management.users")}
                            </Grid>
                            <Grid item>
                                {
                                    // agencies.length !== 0 && (
                                    <CustomAutoComplete
                                        options={userList}
                                        values={userTO}
                                        type={"userTO"}
                                        allSelected={allSelectedUserTO}
                                        customSetter={setUserTO}
                                        handleSelectAll={handleSelectAll}
                                        customLabel={!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) ? t("menu.user_management.all_user_to") : t("menu.user_management.all_user_bo")}
                                        label={!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) ? "Agents TO" : "Agents BO"}
                                    />
                                    // )
                                }
                                {
                                    !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) && agencies.length === 0 && (
                                        <CustomAutoComplete
                                            options={userList}
                                            values={userAgence}
                                            type={"userAgenceTO"}
                                            allSelected={allSelectedUserAgence}
                                            customSetter={setUserAgence}
                                            handleSelectAll={handleSelectAll}
                                            customLabel={t("menu.user_management.all_user_agency")}
                                            label={"Agents Agence"}
                                        />
                                    )
                                }
                            </Grid>
                            {
                                !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) && agencies.length !== 0 && (
                                    <Grid item>
                                        <Autocomplete
                                            options={agencies}
                                            size={"small"}
                                            onChange={(event, selectedOptions) => {
                                                setAgencyFilter(selectedOptions);
                                            }}
                                            getOptionLabel={(option) => getOptionLabel(option, "agency")}
                                            getOptionSelected={(option, value) => option.name === value.name}
                                            value={agencyFilter}
                                            renderInput={(params) => (
                                                <TextField {...params} className={`${classes.formControlRoot2}`} variant={"outlined"} label={t("menu.margin.agency")} />
                                            )}
                                        />
                                    </Grid>

                                )
                            }
                            {
                                !["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) && agencyFilter !== "" && (
                                    <Fragment>
                                        {
                                            userAgenceList.length !== 0 && (
                                                <Grid item>
                                                    <CustomAutoComplete
                                                        options={userAgenceList}
                                                        values={userAgence}
                                                        type={"userAgence"}
                                                        allSelected={allSelectedUserAgence}
                                                        customSetter={setUserAgence}
                                                        handleSelectAll={handleSelectAll}
                                                        customLabel={t("menu.user_management.all_user_agency")}
                                                        label={"Agents Agence"}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    </Fragment>
                                )
                            }
                            {
                                ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) && (
                                    <Fragment>
                                        {
                                            userAgenceList.length !== 0 && (
                                                <Grid item>
                                                    <CustomAutoComplete
                                                        options={userAgenceList}
                                                        values={userAgence}
                                                        type={"userAgence"}
                                                        allSelected={allSelectedUserAgence}
                                                        customSetter={setUserAgence}
                                                        handleSelectAll={handleSelectAll}
                                                        customLabel={!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) ? t("menu.user_management.all_user_agency") : t("menu.user_management.all_sellers")}
                                                        label={!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) ? "Agents Agence" : t("menu.trip_list.seller")}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    </Fragment>
                                )
                            }
                            {
                                creatorList.length !== 0 && (
                                    <Grid item>
                                        <CustomAutoComplete
                                            options={creatorList}
                                            values={creator}
                                            type={"creator"}
                                            allSelected={allSelectedCreator}
                                            customSetter={setCreator}
                                            handleSelectAll={handleSelectAll}
                                            customLabel={t("menu.user_management.all_creator")}
                                            label={"Créateur"}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                    }
                    <Grid container direction={"column"} style={{marginTop: 10, marginBottom: 10, paddingLeft:  8, paddingRight: 8}} spacing={1}>
                        <Button variant={"outlined"} color={"primary"} fullWidth className={`${classes.fontSize12} ${classes.noWrap}`} onClick={handleSaveFiltersOpen} >{customFiltersSelected !== null && (customFiltersSelected.filters_type === 'user' || (['cercledesvoyages'].includes(quotation_code) && user.groups.some(group => group.is_admin === true))) ? t("menu.user_management.edit_filters") : t("menu.user_management.save_filters")}</Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', paddingLeft: 25, paddingRight: 25 }}>
                <Button variant={"outlined"} color={"primary"} fullWidth className={`${classes.fontSize12} ${classes.noWrap}`} onClick={handleReset}>{t("global.clear")}</Button>
                <Button variant={"outlined"} color={"primary"} fullWidth className={`${classes.fontSize12} ${classes.noWrap}`} onClick={() => {
                    applyFilters(false);
                }}>{t("global.apply")}</Button>
            </DialogActions>
            <OrderFiltersDialog orderFiltersOpen={orderFiltersOpen} handleEditFiltersOrder={handleEditFiltersOrder}/>
            <SaveFiltersDialog saveFiltersOpen={saveFiltersOpen} handleSaveFiltersOpen={handleSaveFiltersOpen}/>
            <DeleteFiltersDialog deleteFiltersOpen={deleteFiltersOpen} handleDeleteFiltersOpen={handleDeleteFiltersOpen} id={deleteId} setDeleteId={setDeleteId}/>
        </div>
    );
});
export default TripListFilters;
