import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import axios from "axios";
import { isString, uniqBy } from "lodash";
import { sortItinerary } from "../../Itinerary/utils/sortItinerary";
import { itineraryToItineraryInput } from "../../Itinerary/utils/itineraryToItineraryInput";
import { useCartProducts } from "../../Itinerary/network/cartProducts";
import { useRecomputeItinerary } from "./recomputeItinerary";
import { useItineraryUpdate } from "../../Itinerary/network/itineraryUpdate";
import { useShowError } from "../../Utils/showError";
import { StepsDirectionsManager } from "../../Itinerary/utils/stepsDirectionsManager";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";
import { SetItinerary } from "../../../Actions/Itinerary";
import { AccommodationCart } from "../../Itinerary/objects/accommodationCart";
import { Flight } from "../../Itinerary/network/flight";
import { AppState } from "../../../Reducers/Reducers";
import { CarCart } from "../../Itinerary/objects/carCart";
import { TransferCart } from "../../Itinerary/objects/transferCart";
import { PoiCart } from "../../Itinerary/objects/poiCart";
import { ManualProduct } from "../../Itinerary/objects/manualProduct";
import { AssistanceCart } from "../../Itinerary/objects/assistanceCart";
import { Provider } from "../../Common/objects/provider";
import { ItineraryInput } from "../../Itinerary/objects/itineraryState";
import { ManualProductFlightCart } from "../../Itinerary/objects/manualProductFlightCart";

type Callbacks = {
    onDeleteAccommodation: (product: AccommodationCart) => Promise<void>,
    onDeleteFlight: (product: Flight) => Promise<void>,
    onDeleteCar: (product: CarCart) => Promise<void>,
    onDeleteTransfer: (product: TransferCart) => Promise<void>,
    onDeletePoi: (product: PoiCart) => Promise<void>,
    onDeleteAssistance: (product: AssistanceCart) => Promise<void>,
    onDeleteManualProduct: (product: ManualProduct) => Promise<void>,
}

export function useDeleteProduct(): Callbacks & { loading: boolean } {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const products = useCartProducts();
    const recomputeItinerary = useRecomputeItinerary();
    const showError = useShowError();
    const update = useItineraryUpdate({
        onSuccess(itinerary) {
            dispatch(SetItinerary(itinerary));
        },
        onError(error) {
            if (!isString(error) && !axios.isCancel(error)) {
                showError(error);
            }
        }
    });

    const onRecomputeItinerary = async (inputs: ItineraryInput[]) => {
        const result = await recomputeItinerary(inputs);
        if (result) {
            await update(itinerary, result);
        }
    };

    return {
        loading,
        onDeleteAccommodation: async (product) => {
            const { headers } = CheckBeforeRequest();
            setLoading(true);
            await Promise.all(
                product.rooms.map(async (room, index_room) => {
                    if (room.booking_status && room.booking_status.status_booking === "CANCELLED") {
                        try {
                            const response = await axios({
                                method: 'PATCH',
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                                data: {
                                    is_displayed: false
                                }
                            });
                            dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                            enqueueSnackbar(`${t('cart-material.room-deleted-1')} ${index_room} ${t('cart-material.room-deleted-2')}`, { variant: 'success' });
                        } catch (error) {
                            console.log(error);
                            enqueueSnackbar(`${t('cart-material.room-not-deleted-1')} ${index_room} ${t('cart-material.room-not-deleted-2')}`, { variant: 'error' });
                        }
                    } else {
                        try {
                            await axios({
                                method: 'DELETE',
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`
                            });
                            dispatch({ type: 'ACCOMMODATION_REMOVE_FROM_CART_BY_ID', payload: room.id });
                            enqueueSnackbar(`${t('cart-material.room-deleted-1')} ${index_room} ${t('cart-material.room-deleted-2')}`, { variant: 'success' });
                        } catch (error) {
                            console.log(error);
                            enqueueSnackbar(`${t('cart-material.room-not-deleted-1')} ${index_room} ${t('cart-material.room-not-deleted-2')}`, { variant: 'error' });
                        }
                    }
                })
            );
            setLoading(false);
        },
        onDeleteFlight: async (product) => {
            const { headers } = CheckBeforeRequest();
            setLoading(true);

            const inputs = itinerary.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary);

            const manager = StepsDirectionsManager.getInstance();
            for (let i = 0; i < inputs.length; i++) {
                const step = inputs[i]!;
                const nextStep = inputs[i + 1];
                if (
                    step.r2r_json?.selected &&
                    step.r2r_json.vehicle?.kind === 'plane' &&
                    nextStep
                ) {
                    if (nextStep) {
                        const position = await manager.transformStepToCoordinates(step);
                        const nextStepPosition = await manager.transformStepToCoordinates(nextStep);
                        const flights = products.flights.filter((item) => {
                            return item.type === 'normal';
                        }).map((item) => {
                            return item.flight as Flight;
                        });

                        const correspondingFlights: {
                            departure: string,
                            flight: typeof flights[number]
                        }[] = [];

                        for (const flight of flights) {
                            for (const outbound of flight.outbounds) {
                                let originLeg: typeof outbound.legs[number] | undefined;
                                const inBetweenLegs: typeof outbound.legs = [];
                                let destinationLeg: typeof outbound.legs[number] | undefined;

                                for (const leg of outbound.legs) {
                                    const originPosition = new google.maps.LatLng({
                                        lat: parseFloat(leg.origin?.latitude ?? '0'),
                                        lng: parseFloat(leg.origin?.longitude ?? '0')
                                    });
                                    const destinationPosition = new google.maps.LatLng({
                                        lat: parseFloat(leg.destination?.latitude ?? '0'),
                                        lng: parseFloat(leg.destination?.longitude ?? '0')
                                    });
                                    if (
                                        google.maps.geometry.spherical.computeDistanceBetween(
                                            position,
                                            originPosition
                                        ) <= 100000
                                    ) {
                                        originLeg = leg;
                                    }

                                    if (
                                        google.maps.geometry.spherical.computeDistanceBetween(
                                            nextStepPosition,
                                            destinationPosition
                                        ) <= 100000
                                    ) {
                                        destinationLeg = leg;
                                        break;
                                    }

                                    if (
                                        google.maps.geometry.spherical.computeDistanceBetween(
                                            position,
                                            originPosition
                                        ) > 100000 &&
                                        google.maps.geometry.spherical.computeDistanceBetween(
                                            nextStepPosition,
                                            destinationPosition
                                        ) > 100000
                                    ) {
                                        inBetweenLegs.push(leg);
                                    }
                                }

                                if (
                                    originLeg &&
                                    destinationLeg
                                ) {
                                    const legsDuration = uniqBy(
                                        [
                                            originLeg,
                                            ...inBetweenLegs,
                                            destinationLeg
                                        ],
                                        (leg) => leg.id
                                    ).reduce((prev, current) => {
                                        return prev + current.flight_time;
                                    }, 0);
                                    if (legsDuration === step.r2r_json?.duration) {
                                        correspondingFlights.push({
                                            departure: originLeg.departure_time,
                                            flight
                                        });
                                    }
                                }
                            }
                        }

                        const nearestDateFlight = correspondingFlights.sort((a, b) => {
                            const aDate = window.moment.utc(a.departure);
                            const bDate = window.moment.utc(b.departure);
                            const aDiff = Math.abs(
                                window.moment.utc(step.end_date).diff(
                                    aDate,
                                    'minutes'
                                )
                            );
                            const bDiff = Math.abs(
                                window.moment.utc(step.end_date).diff(
                                    bDate,
                                    'minutes'
                                )
                            );
                            return aDiff - bDiff;
                        })[0];

                        if (nearestDateFlight?.flight.id === product.id) {
                            step.r2r_json = {};
                        }
                    }
                }
            }

            await onRecomputeItinerary(inputs);

            if (product.booking_status && product.booking_status.status_booking === 'CANCELLED') {
                try {
                    const response = await axios({
                        method: 'PATCH',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${product.id}/`,
                        data: {
                            is_displayed: false
                        }
                    });
                    let new_flight = [];
                    new_flight.push(response.data);
                    let arr = CheckResponse(new_flight, trip?.end_date);
                    dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
                    enqueueSnackbar(t('cart-material.flight-deleted'), { variant: 'success' });
                } catch (error) {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.flight-not-deleted'), { variant: 'error' });
                }
            } else {
                try {
                    await axios({
                        method: 'DELETE',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${product.id}/`
                    });
                    dispatch({ type: 'FLIGHT_REMOVE_FLIGHT_FROM_CART_BY_ID', payload: product.id });
                    enqueueSnackbar(t('cart-material.flight-deleted'), { variant: 'success' });
                } catch (error) {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.flight-not-deleted'), { variant: 'error' });
                }
            }

            setLoading(false);
        },
        onDeleteCar: async (product) => {
            const { headers } = CheckBeforeRequest();
            setLoading(true);

            const inputs = itinerary.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary);

            for (let i = 0; i < inputs.length; i++) {
                const step = inputs[i]!;
                const nextStep = inputs[i + 1];
                if (
                    step.r2r_json?.selected &&
                    step.r2r_json.vehicle?.kind === 'car' &&
                    nextStep
                ) {
                    const correspondingCar = products.cars.find((item) => {
                        return window.moment.utc(item.car.start_date).isSame(
                            window.moment.utc(step.end_date)
                        );
                    });

                    if (
                        correspondingCar?.type === 'normal' &&
                        correspondingCar?.car.id === product.id
                    ) {
                        step.r2r_json = {};
                    }
                }
            }

            await onRecomputeItinerary(inputs);

            if (product.booking_status && product.booking_status.status_booking === 'CANCELLED') {
                try {
                    const response = await axios({
                        method: 'PATCH',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${product.id}/`,
                        data: {
                            is_displayed: false
                        }
                    });
                    dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
                    enqueueSnackbar(t('cart-material.car-deleted'), { variant: 'success' });
                } catch (error) {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.car-not-deleted'), { variant: 'error' });
                }
            } else {
                try {
                    await axios({
                        method: 'DELETE',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${product.id}/`
                    });
                    dispatch({ type: 'CAR_REMOVE_FROM_CART_BY_ID', payload: product.id });
                    enqueueSnackbar(t('cart-material.car-deleted'), { variant: 'success' });
                } catch (error) {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.car-not-deleted'), { variant: 'error' });
                }
            }
            setLoading(false);
        },
        onDeleteTransfer: async (product) => {
            const { headers } = CheckBeforeRequest();
            setLoading(true);

            const inputs = itinerary.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary);

            for (let i = 0; i < inputs.length; i++) {
                const step = inputs[i]!;
                const nextStep = inputs[i + 1];
                if (
                    step.r2r_json?.selected &&
                    step.r2r_json.vehicle?.kind === 'car' &&
                    nextStep
                ) {
                    const correspondingCar = products.transfers.find((item) => {
                        return window.moment.utc(item.transfer.start_date).isSame(
                            window.moment.utc(step.end_date)
                        );
                    });

                    if (
                        correspondingCar?.type === 'normal' &&
                        correspondingCar?.transfer.id === product.id
                    ) {
                        step.r2r_json = {};
                    }
                }
            }

            await onRecomputeItinerary(inputs);

            if (
                product.booking_status &&
                product.booking_status.status_booking === 'CANCELLED'
            ) {
                try {
                    const response = await axios({
                        method: 'PATCH',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${product.is_custom ? 'custom-products' : 'transfers'}/${product.id}/`,
                        data: {
                            is_displayed: false
                        }
                    });
                    dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
                    enqueueSnackbar(t('cart-material.transfer-deleted'), { variant: 'success' });
                } catch (error) {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.tranfer-not-deleted'), { variant: 'error' });
                }
            } else {
                try {
                    await axios({
                        method: 'DELETE',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${product.is_custom ? 'custom-products' : 'transfers'}/${product.id}/`
                    });
                    dispatch({ type: 'TRANSFER_REMOVE_FROM_CART_BY_ID', payload: product.id });
                    enqueueSnackbar(t('cart-material.transfer-deleted'), { variant: 'success' });
                } catch (error) {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.tranfer-not-deleted'), { variant: 'error' });
                }
            }
            setLoading(false);
        },
        onDeletePoi: async (product) => {
            const { headers } = CheckBeforeRequest();
            setLoading(true);
            if (product.booking_status && product.booking_status.status_booking === "CANCELLED") {
                try {
                    const response = await axios({
                        method: 'PATCH',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product.id}/`,
                        data: {
                            is_displayed: false
                        }
                    });
                    dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
                    enqueueSnackbar(t('cart-material.poi-deleted'), { variant: 'success' });
                } catch (error) {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.poi-not-deleted'), { variant: 'error' });
                }
            } else {
                try {
                    await axios({
                        method: 'DELETE',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product.id}/`
                    });
                    dispatch({ type: 'POI_REMOVE_CART_BY_ID', payload: product.id });
                    enqueueSnackbar(t('cart-material.poi-deleted'), { variant: 'success' });
                } catch (error) {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.poi-not-deleted'), { variant: 'error' });
                }
            }
            setLoading(false);
        },
        onDeleteAssistance: async (product) => {
            const { headers } = CheckBeforeRequest();
            let provider: Provider | null = null;
            for (let i = 0; i < providers.length; i++) {
                if (providers[i]?.provider.id === product.provider) {
                    provider = providers[i]!;
                }
            }
            for (let i = 0; i < manual_providers.length; i++) {
                if (manual_providers[i]?.provider.id === product.provider) {
                    provider = manual_providers[i]!;
                }
            }
            setLoading(true);
            if (product.booking_status && product.booking_status.status_booking === "CANCELLED") {
                try {
                    const response = await axios({
                        method: 'PATCH',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${["gritchen", "intermundial"].includes(provider?.provider.code) ? 'insurance' : 'assistance'}/${product.id}/`,
                        data: {
                            is_displayed: false
                        }
                    });
                    if (product.product_type === 8) {
                        enqueueSnackbar(t('cart-material.insurance-deleted'), { variant: 'success' });
                    } else {
                        enqueueSnackbar(t('cart-material.assistance-deleted'), { variant: 'success' });
                    }
                    dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
                } catch (error) {
                    console.log(error);
                    if (product.product_type === 8) {
                        enqueueSnackbar(t('cart-material.insurance-not-deleted'), { variant: 'error' });
                    } else {
                        enqueueSnackbar(t('cart-material.assistance-not-deleted'), { variant: 'error' });
                    }
                }
            } else {
                try {
                    await axios({
                        method: 'DELETE',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${["gritchen", "intermundial"].includes(provider?.provider.code) ? 'insurance' : 'assistance'}/${product.id}/`
                    });
                    if (product.product_type === 8) {
                        enqueueSnackbar(t('cart-material.insurance-deleted'), { variant: 'success' });
                    } else {
                        enqueueSnackbar(t('cart-material.assistance-deleted'), { variant: 'success' });
                    }
                    dispatch({ type: 'CART_ASSISTANCE_DELETE_BY_ID', payload: product.id });
                } catch (error) {
                    console.log(error);
                    if (product.product_type === 8) {
                        enqueueSnackbar(t('cart-material.insurance-not-deleted'), { variant: 'error' });
                    } else {
                        enqueueSnackbar(t('cart-material.assistance-not-deleted'), { variant: 'error' });
                    }
                }
            }
            setLoading(false);
        },
        onDeleteManualProduct: async (product) => {
            const { headers } = CheckBeforeRequest();
            setLoading(true);

            if (product.product_type === 6) {
                const inputs = itinerary.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary);

                const manager = StepsDirectionsManager.getInstance();
                for (let i = 0; i < inputs.length; i++) {
                    const step = inputs[i]!;
                    const nextStep = inputs[i + 1];
                    if (
                        step.r2r_json?.selected &&
                        step.r2r_json.vehicle?.kind === 'plane' &&
                        nextStep
                    ) {
                        if (nextStep) {
                            const position = await manager.transformStepToCoordinates(step);
                            const nextStepPosition = await manager.transformStepToCoordinates(nextStep);
                            const flights = products.flights.filter((item) => {
                                return item.type === 'manual';
                            }).map((item) => {
                                return item.flight as ManualProductFlightCart;
                            });

                            const correspondingFlights: {
                                departure: string,
                                flight: typeof flights[number]
                            }[] = [];

                            for (const flight of flights) {
                                let correspondingOutbound: typeof flight.flight_segment[number] | undefined;
                                for (const outbound of flight.flight_segment) {
                                    const originPosition = new google.maps.LatLng({
                                        lat: outbound.origin_airport?.latitude ?? 0,
                                        lng: outbound.origin_airport?.longitude ?? 0
                                    });
                                    const destinationPosition = new google.maps.LatLng({
                                        lat: parseFloat(outbound.destination_airport?.latitude ?? '0'),
                                        lng: parseFloat(outbound.destination_airport?.longitude ?? '0')
                                    });
                                    if (
                                        google.maps.geometry.spherical.computeDistanceBetween(
                                            position,
                                            originPosition
                                        ) <= 100000 &&
                                        google.maps.geometry.spherical.computeDistanceBetween(
                                            nextStepPosition,
                                            destinationPosition
                                        ) <= 100000
                                    ) {
                                        correspondingOutbound = outbound;
                                        break;
                                    }
                                }
                                if (correspondingOutbound) {
                                    correspondingFlights.push({
                                        departure: correspondingOutbound.start_date,
                                        flight
                                    });
                                }
                            }

                            const nearestDateFlight = correspondingFlights.sort((a, b) => {
                                const aDate = window.moment.utc(a.departure);
                                const bDate = window.moment.utc(b.departure);
                                const aDiff = Math.abs(
                                    window.moment.utc(step.end_date).diff(
                                        aDate,
                                        'minutes'
                                    )
                                );
                                const bDiff = Math.abs(
                                    window.moment.utc(step.end_date).diff(
                                        bDate,
                                        'minutes'
                                    )
                                );
                                return aDiff - bDiff;
                            })[0];

                            if (nearestDateFlight?.flight.id === product.id) {
                                step.r2r_json = {};
                            }
                        }
                    }
                }

                await onRecomputeItinerary(inputs);
            }

            if (product.product_type === 2) {
                const inputs = itinerary.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary);

                for (let i = 0; i < inputs.length; i++) {
                    const step = inputs[i]!;
                    const nextStep = inputs[i + 1];
                    if (
                        step.r2r_json?.selected &&
                        step.r2r_json.vehicle?.kind === 'car' &&
                        nextStep
                    ) {
                        const correspondingCar = products.cars.find((item) => {
                            return window.moment.utc(item.car.start_date).isSame(
                                window.moment.utc(step.end_date)
                            );
                        });

                        if (
                            correspondingCar?.type === 'manual' &&
                            correspondingCar?.car.id === product.id
                        ) {
                            step.r2r_json = {};
                        }
                    }
                }

                await onRecomputeItinerary(inputs);
            }

            if (product.product_type === 4) {
                const inputs = itinerary.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary);

                for (let i = 0; i < inputs.length; i++) {
                    const step = inputs[i]!;
                    const nextStep = inputs[i + 1];
                    if (
                        step.r2r_json?.selected &&
                        step.r2r_json.vehicle?.kind === 'car' &&
                        nextStep
                    ) {
                        const correspondingCar = products.transfers.find((item) => {
                            return window.moment.utc(item.transfer.start_date).isSame(
                                window.moment.utc(step.end_date)
                            );
                        });

                        if (
                            correspondingCar?.type === 'manual' &&
                            correspondingCar?.transfer.id === product.id
                        ) {
                            step.r2r_json = {};
                        }
                    }
                }

                await onRecomputeItinerary(inputs);
            }

            try {
                await axios({
                    method: 'DELETE',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${product.id}/`
                });
                dispatch({ type: 'CART_MANUAL_PRODUCT_REMOVE_FROM_CART_BY_ID', payload: product.id });
                enqueueSnackbar(t('cart-material.manual-product-deleted'), { variant: 'success' });
            } catch (error) {
                console.log(error);
                enqueueSnackbar(t('cart-material.manual-product-not-deleted'), { variant: 'error' });
            }
            setLoading(false);
        }
    };
}
