export default (status) => {
    const quotation_code = JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code;
    switch (status) {
        case "ONGOING":
        case "QUOTATION" :
        case "TRANSMITTED" :
            return "#ffeb3b";
        case "QUOTATION_SENT":
            return "#FF5F1F";
        case "VALIDATED" :
            if (quotation_code === "volonline") {
                return "#FF5F1F";
            }
            return "#ed4b82";
        case "AGENCY_MOD" :
            return "lightgrey";
        case "COUNTEROFFER":
            return "#00bfff";
        case "TO_TRANSMIT":
            return "#ed4b82";
        case "AMENDMENT" :
            return "#FF5F1F";
        case "TO_UPDATED" :
            return "#0b3e6f";
        case "USER_VALID":
            if (quotation_code === "volonline") {
                return "#8bc34a";
            }
            return "#6d1b7b";
        case "TOBOOK" :
            return "#673ab7";
        case "TOBOOK_VERDIE" :
            return "#673ab7";
        case "DEPOSIT" :
            return "#8bc34a";
        case "ONREQUEST":
            return "#ff5722";
        case "BOOKED" :
        case "CONFIRMED" :
        case "FIXED_PV" :
        case "PROCESSED" :
            return "#4caf50";
        case "ADDITEM":
            if (quotation_code === "volonline") {
                return "#ed4b82";
            }
            return "#e91e63";
        case "CLOSED":
            return "#000000";
        case "CANCELED":
        case "CANCELLED":
        case "CANCELLED_CDV":
        case "CANCELLED_CGV":
        case "NOT_AVAILABLE":
            return "#ff0000";
        case "ARCHIVED":
            return "#696969";
        case "INPROGRESS" :
        case "IN_PROGRESS" :
            return "#00bfff";
        case "FINISHED" :
            return "#00008b";
    } 
    return null;
};
