import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { cloneDeep, flatten, isNumber, max, uniq } from "lodash";
import axios, { AxiosResponse } from "axios";
import { itineraryToItineraryInput } from "../utils/itineraryToItineraryInput";
import { makeContentUpdateRequest } from "./itineraryContentUpdate";
import { useItineraryDuplicateTripProducts } from "./itineraryDuplicateTripProducts";
import { useShowError } from "../../Utils/showError";
import { useProductDays } from "../../CartMaterial/utils/productDays";
import { useCircuitCorrespondingPricePeriod } from "../utils/circuitCorrespondingPricePeriod";
import { useFixedDateBlockCheck } from "./fixedDateBlockCheck";
import { getCircuit } from "../utils/getCircuit";
import { sortItinerary } from "../utils/sortItinerary";
import { extractStepsForContentByDay, extractStepsForContentByStep } from "./itineraryContent";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";
import GetCookie from "../../Common/Functions/GetCookie";
import { StepsDirectionsManager } from "../utils/stepsDirectionsManager";
import { StepsDatesManager } from "../utils/stepsDatesManager";
import {
    addBlock,
    markIndexAsCalculatingTransport,
    setAdalteModalAddOptions,
    setShouldNormalizeTexts
} from "../redux/reducer";
import { SetCart } from "../../../Actions/Accommodation";
import { SetManualCart } from "../../../Actions/Cart";
import { SetFlightCart } from "../../../Actions/Flight";
import { SetCarsCart } from "../../../Actions/CarsSearch";
import { ConcatTransfersCart } from "../../../Actions/Transfers";
import { SetPoiCart } from "../../../Actions/Poi";
import { TripBlock } from "../objects/tripBlock";
import { SelectedRoom } from "../../ItineraryType/RoomTypePicker";
import { ItineraryState } from "../objects/itineraryState";
import { ItineraryByDay } from "../objects/itineraryByDay";
import { ItineraryByStep } from "../objects/itineraryByStep";
import { ItineraryContentStep } from "../objects/itineraryContentStep";
import { Trip } from "../../Menu/MaterialTripList/objects/trip";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (
    options: {
        tripData?: Trip | null,
        tripId: number,
        tripVersion: number,
        steps: number[],
        index: number,
        dryRun?: boolean
    } & ({
        type: 'circuit',
        circuitId: number | null,
        circuitVersion: number | null,
        circuitVariant: number,
        circuitDate: string,
        circuit: ItineraryState['blocks']['circuits']['cache'][number][number],
        circuitSource: string,
        products?: number[],
        adalteRooms?: SelectedRoom[],
        travelExchangeOptions?: {
            selectedTravelExchangePrices: any,
            selectedTravelExchangeOptions: any,
            mealPlan: any
        }
    } | {
        type: 'typical-trip',
        trip: TripBlock
    })
) => Promise<boolean | { startDate: string }>

export function useItineraryBlockAdd(): Callback {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const tripAllData = useSelector((state: AppState) => state.trip.all_data);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const stepInputs = useSelector((state: AppState) => state.itinerarySlice.stepsInputs);
    const steps = useMemo(() => {
        return stepInputs.filter((item) => {
            return ['START', 'STEP'].includes(item.step_type);
        });
    }, [stepInputs]);
    const dupliateTripProducts = useItineraryDuplicateTripProducts({});
    const getCorrespondingPricePeriod = useCircuitCorrespondingPricePeriod();
    const checkForFixedDateBlock = useFixedDateBlockCheck();
    const getDays = useProductDays();
    const showError = useShowError();

    return useCallback(
        async (options) => {
            const trip = options.tripData ?? tripAllData;

            let block = (
                options.type === 'circuit' ?
                    options.circuit.steps :
                    options.trip.data[0]?.steps ?? []
            ).filter((step) => {
                return step.step_type === 'STEP';
            }).filter((item) => {
                return options.steps.includes(item.id);
            }).map((item) => {
                return {
                    ...itineraryToItineraryInput(locale, item),
                    circuit: options.type !== 'typical-trip' ?
                        options.circuitId :
                        null,
                    circuit_trip_version: options.type !== 'typical-trip' ?
                        options.circuitVersion :
                        null,
                    iti_type: options.type === 'typical-trip' ?
                        options.tripId :
                        null
                };
            });

            const { pass_check, headers } = CheckBeforeRequest();

            let byDayPackageTexts: Omit<ItineraryByDay, 'interval'>[] = [];
            let byStepPackageTexts: Omit<ItineraryByStep, 'interval'>[] = [];
            if (block[0] && tripStartDate && tripEndDate) {
                const manager = StepsDirectionsManager.getInstance();
                const datesManager = new StepsDatesManager(
                    tripStartDate,
                    tripEndDate
                );


                dispatch(
                    markIndexAsCalculatingTransport({
                        index: options.index - 1,
                        isCalculating: true
                    })
                );

                const startDate = window.moment.utc(tripStartDate).set({
                    hour: 9,
                    minute: 0,
                    second: 0
                });
                let lastStep = cloneDeep(steps[options.index - 1]);
                let belowBlockStep = cloneDeep(steps[options.index]);

                const delta = window.moment.utc(block[0].end_date).startOf('day').diff(window.moment.utc(block[0].start_date).startOf('day'), "days");
                block[0].start_date = startDate.toISOString();
                block[0].end_date = window.moment.utc(block[0].start_date).add(delta, "days").toISOString();
                // block[0].end_date = startDate.toISOString();
                block[0].circuit_start_date = options.type === 'circuit' && options.circuitDate !== 'PERIOD' ?
                    options.circuitDate :
                    null;
                for (let i = 0; i < block.length; i++) {
                    let current = cloneDeep(block[i]!);
                    const next = cloneDeep(block[i + 1]);

                    if (
                        i === 0 &&
                        lastStep
                    ) {
                        let [above, step] = await manager.recomputeTransportsBetween(
                            lastStep,
                            current,
                            true
                        );
                        const resultPair = datesManager.recomputeDates(above, step);
                        lastStep = resultPair[0];
                        block[0] = current = resultPair[1];
                    }

                    if (
                        i === block.length - 1 &&
                        belowBlockStep
                    ) {
                        let [above, step] = await manager.recomputeTransportsBetween(
                            current,
                            belowBlockStep,
                            true
                        );
                        const resultPair = datesManager.recomputeDates(above, step);
                        block[i] = current = resultPair[0];
                        belowBlockStep = resultPair[1];
                    }

                    if (next) {
                        let [above, step] = await manager.recomputeTransportsBetween(
                            current,
                            next,
                            true
                        );
                        const resultPair = datesManager.recomputeDates(above, step);
                        block[i] = resultPair[0];
                        block[i + 1] = resultPair[1];
                    }
                }

                const firstBlockStep = block[0];
                const lastBlockStep = block[block.length - 1];

                // check periods if it is a circuit / package
                if (options.type === 'circuit' && pass_check && isNumber(options.circuitId)) {
                    try {
                        let periods = options.circuit.periods ?? [];
                        const periodIds = flatten(
                            options.circuit?.priceTerrestrial?.map((item) => {
                                return item.periods;
                            }).concat(
                                options.circuit.priceFlight?.map((item) => {
                                    return item.periods;
                                }) ?? []
                            )
                        );
                        periods = periods.filter((period) => {
                            return periodIds.includes(period.id);
                        });
                        const circuit = await getCircuit(options.circuitId);
                        if (
                            circuit?.forfait_price === false &&
                            (
                                options.circuitDate !== 'PERIOD' ||
                                periods.length > 0
                            )
                        ) {
                            const period = await getCorrespondingPricePeriod({
                                circuitId: options.circuitId,
                                circuitVersion: options.circuitVersion,
                                circuitVariants: [options.circuitVariant],
                                circuitDate: options.circuitDate,
                                periods,
                                step: firstBlockStep,
                                block
                            });

                            if (!period && periods.length > 0) {
                                enqueueSnackbar(
                                    t('itinerary.add-block-dates-error'),
                                    { variant: 'error' }
                                );
                                dispatch(
                                    markIndexAsCalculatingTransport({
                                        index: options.index - 1,
                                        isCalculating: false
                                    })
                                );
                                return false;
                            }

                            if (
                                lastBlockStep &&
                                belowBlockStep &&
                                checkForFixedDateBlock({
                                    aboveStep: lastBlockStep,
                                    step: lastBlockStep,
                                    belowStep: belowBlockStep
                                }) === false
                            ) {
                                enqueueSnackbar(
                                    t('itinerary.add-block-dates-incompatible-blocks-error'),
                                    { variant: 'error' }
                                );
                                dispatch(
                                    markIndexAsCalculatingTransport({
                                        index: options.index - 1,
                                        isCalculating: false
                                    })
                                );
                                return false;
                            }

                            if (
                                firstBlockStep &&
                                lastStep &&
                                checkForFixedDateBlock({
                                    aboveStep: lastStep,
                                    step: firstBlockStep,
                                    belowStep: firstBlockStep
                                }) === false
                            ) {
                                enqueueSnackbar(
                                    t('itinerary.add-block-dates-error'),
                                    { variant: 'error' }
                                );
                                dispatch(
                                    markIndexAsCalculatingTransport({
                                        index: options.index - 1,
                                        isCalculating: false
                                    })
                                );
                                return false;
                            }
                        }
                    } catch (error) {
                        showError(error as Error);
                        return false;
                    }
                }

                if (options.dryRun) {
                    dispatch(
                        markIndexAsCalculatingTransport({
                            index: options.index - 1,
                            isCalculating: false
                        })
                    );
                    return { startDate: firstBlockStep.start_date };
                }

                const version = parseInt(GetCookie('trip_id_version') ?? '-1');
                try {
                    await dupliateTripProducts({
                        sourceTripId: options.tripId,
                        sourceTripVersion: options.tripVersion,
                        startDate: firstBlockStep.start_date,
                        targetTripVersion: version,
                        products: options.type === 'circuit' ? options.products : undefined,
                        adalteRooms: options.type === 'circuit' ? options.adalteRooms : undefined,
                        travelExchangeOptions: options.type === 'circuit' ? options.travelExchangeOptions : undefined
                    });
                } catch (error) {
                    showError(error as Error);
                    return false;
                } finally {
                    dispatch(
                        markIndexAsCalculatingTransport({
                            index: options.index - 1,
                            isCalculating: false
                        })
                    );
                }

                if (pass_check) {
                    let request_product = [];
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/accommodation/?token=${GetCookie("trip_token")}`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/assistance/?token=${GetCookie("trip_token")}`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/custom-products/?token=${GetCookie("trip_token")}`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/manual-products/?token=${GetCookie("trip_token")}`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/flight/`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/transfers/`
                        })
                    );
                    request_product.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/car/`
                        })
                    );
                    axios.all([...request_product]).then(axios.spread((...responses) => {
                        responses.forEach((response) => {
                            if (response.config.url?.includes("accommodation")) {
                                dispatch(SetCart(response.data));
                                response.data.map((room: any) => {
                                    if (room.booking_status !== null && room.booking_status.confirmation_reference === null && room.provider === 121) {
                                        axios({
                                            method: 'POST',
                                            headers: headers,
                                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/check_status/?product_types=${room.product_type}`,
                                            data: {
                                                items: [room.id]
                                            }
                                        }).then(response => {
                                            dispatch({ type: 'ACCOMMODATION_UPDATE_CART', payload: response.data });
                                        }).catch(error => {
                                            console.log(error);
                                        });
                                    }
                                });
                            }
                            if (response.config.url?.includes("manual-products")) {
                                if (response.data.length > 0) {
                                    let products: any[] = [];
                                    response.data.map((product: any) => {
                                        if (Object.keys(product).length > 0) {
                                            products.push(product);
                                        }
                                    });
                                    dispatch(SetManualCart(products));
                                }
                            }
                            if (response.config.url?.includes("flight")) {
                                if (response.data.length > 0) {
                                    let arr = CheckResponse(response.data, tripEndDate);
                                    dispatch(SetFlightCart(arr));
                                }
                            }
                            if (response.config.url?.includes("car")) {
                                let cars_data = response.data;
                                if (response.data.length > 0) {
                                    cars_data.map((item: any) => {
                                        item.detail = false;
                                        item.charges.sort((a: any, b: any) => {
                                            //eslint-disable-next-line no-nested-ternary
                                            return ((parseFloat(a.amount) < parseFloat(b.amount)) ? -1 : ((parseFloat(a.amount) > parseFloat(b.amount)) ? 1 : 0));
                                        });
                                    });
                                    dispatch(SetCarsCart(cars_data));
                                }
                            }

                            if (response.config.url?.includes("transfers")) {
                                dispatch(ConcatTransfersCart(response.data));
                            }
                            if (response.config.url?.includes("custom-products")) {
                                let tmp_poi: any[] = [];
                                let tmp_transfers: any[] = [];
                                response.data.map((el: any) => {
                                    if (el.product_type === 4) {
                                        tmp_transfers.push(el);
                                    } else {
                                        tmp_poi.push(el);
                                    }
                                });
                                dispatch(SetPoiCart(tmp_poi));
                                dispatch(ConcatTransfersCart(tmp_transfers));
                            }
                            if (response.config.url?.includes("assistance")) {
                                dispatch({ type: 'CART_ASSISTANCE_SET_CART', payload: response.data });
                            }
                        });
                    }));
                }

                // duplicate texts
                if (options.type === 'circuit') {
                    if (trip?.is_by_day_view) {
                        // get package by day texts
                        let next: string | null = `${API_HREF}client/${window.id_owner}/circuits/${options.circuitId}/by-day/`;
                        do {
                            const response: AxiosResponse<{
                                next: string | null,
                                results: ItineraryByDay[]
                            }> = await axios.get(
                                next,
                                { headers }
                            );
                            const texts = response.data.results.filter((item) => {
                                return item.trip_version === options.circuitVersion;
                            });
                            for (const item of texts) {
                                byDayPackageTexts.push(item);
                            }
                            next = response.data.next;
                        } while (next);
                    } else {
                        // get package by step texts
                        let next: string | null = `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.circuitVersion}/by-step/`;
                        do {
                            const response: AxiosResponse<{
                                next: string | null,
                                results: ItineraryByStep[]
                            }> = await axios.get(
                                next,
                                { headers }
                            );
                            for (const item of response.data.results) {
                                byStepPackageTexts.push(item);
                            }
                            next = response.data.next;
                        } while (next);
                    }

                    if (trip?.is_by_day_view) {
                        // get trip day by day texts
                        let next: string | null = `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${version}/by-day/`;
                        let targetTexts: ItineraryByDay[] = []
                        do {
                            const response: AxiosResponse<{
                                next: string | null,
                                results: ItineraryByDay[]
                            }> = await axios.get(
                                next,
                                { headers }
                            );
                            for (const item of response.data.results) {
                                targetTexts.push(item);
                            }
                            next = response.data.next;
                        } while (next);

                        // try to remove junk texts
                        const texts = extractStepsForContentByDay(
                            parseInt(GetCookie('trip_id_version') ?? '0'),
                            [...itinerary].sort(sortItinerary),
                            targetTexts
                        );
                        await makeContentUpdateRequest({
                            tripId: parseInt(GetCookie('trip_id') ?? '0'),
                            version: parseInt(GetCookie('trip_id_version') ?? '0'),
                            items: {
                                mode: 'by-day',
                                content: targetTexts
                            },
                            steps: texts
                        });

                        // get by day texts with new ids
                        next = `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${version}/by-day/`;
                        targetTexts = []
                        do {
                            const response: AxiosResponse<{
                                next: string | null,
                                results: ItineraryByDay[]
                            }> = await axios.get(
                                next,
                                { headers }
                            );
                            for (const item of response.data.results) {
                                targetTexts.push(item);
                            }
                            next = response.data.next;
                        } while (next);

                        const dayOffset = (
                            getDays(
                                block[0].start_date,
                            )[0] ?? 1
                        ) - 1;

                        if (byDayPackageTexts.length > 0) {
                            // add day offset to texts
                            const result = byDayPackageTexts.map((item) => ({
                                ...item,
                                day: item.day.map((day) => day + dayOffset)
                            }));

                            // add package texts to trip texts
                            const resultMaxDay = max(
                                uniq(
                                    flatten(
                                        result.map((item) => {
                                            return item.day
                                        })
                                    )
                                )
                            ) ?? 1;
                            let newContent: ItineraryContentStep[] = flatten(
                                targetTexts.map((item): ItineraryContentStep[] => {
                                    if (item.day.some((day) => day > dayOffset)) {
                                        return [
                                            {
                                                mode: 'by-day',
                                                content: {
                                                    ...item,
                                                    day: item.day.filter((day) => day > dayOffset).map((day) => {
                                                        return resultMaxDay + (day - dayOffset);
                                                    })
                                                }
                                            },
                                        ].filter((item) => {
                                            return item.mode === 'by-day' && item.content.day.length > 0;
                                        }) as ItineraryContentStep[];
                                    }
                                    return [{
                                        mode: 'by-day',
                                        content: item
                                    }];
                                })
                            ).concat(
                                result.map((item): ItineraryContentStep => {
                                    return {
                                        mode: 'by-day',
                                        content: {
                                            ...item,
                                            circuit: options.circuitId,
                                            circuit_trip_version: options.circuitVersion,
                                            interval: [],
                                            id: undefined
                                        }
                                    }
                                })
                            ).sort((a, b) => {
                                if (a.mode === 'by-day' && b.mode === 'by-day') {
                                    return (a.content.day[0] ?? 0) - (b.content.day[0] ?? 0);
                                }
                                return -1;
                            });

                            // patch texts and let itinerary ui know that texts should be normalized
                            await makeContentUpdateRequest({
                                tripId: parseInt(GetCookie('trip_id') ?? '0'),
                                version: parseInt(GetCookie('trip_id_version') ?? '0'),
                                items: {
                                    mode: 'by-day',
                                    content: targetTexts
                                },
                                steps: newContent
                            });
                            dispatch(setShouldNormalizeTexts(true));
                        } else {
                            enqueueSnackbar(t('itinerary.bloc-has-no-by-day-texts'), { variant: 'warning' });
                        }
                    } else {
                        // get trip step by step texts
                        let next: string | null = `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${version}/by-step/`;
                        let targetTexts: ItineraryByStep[] = []
                        do {
                            const response: AxiosResponse<{
                                next: string | null,
                                results: ItineraryByStep[]
                            }> = await axios.get(
                                next,
                                { headers }
                            );
                            for (const item of response.data.results) {
                                targetTexts.push(item);
                            }
                            next = response.data.next;
                        } while (next);

                        // try to remove junk texts
                        const texts = extractStepsForContentByStep(
                            parseInt(GetCookie('trip_id_version') ?? '0'),
                            [...itinerary].sort(sortItinerary),
                            targetTexts
                        );
                        await makeContentUpdateRequest({
                            tripId: parseInt(GetCookie('trip_id') ?? '0'),
                            version: parseInt(GetCookie('trip_id_version') ?? '0'),
                            items: {
                                mode: 'by-step',
                                content: targetTexts
                            },
                            steps: texts
                        });

                        // get by step texts with new ids
                        next = `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${version}/by-step/`;
                        targetTexts = []
                        do {
                            const response: AxiosResponse<{
                                next: string | null,
                                results: ItineraryByStep[]
                            }> = await axios.get(
                                next,
                                { headers }
                            );
                            for (const item of response.data.results) {
                                targetTexts.push(item);
                            }
                            next = response.data.next;
                        } while (next);

                        if (byStepPackageTexts.length > 0) {
                            // add package texts to trip texts
                            let newContent: ItineraryContentStep[] = [];

                            if (options.index - 1 < 0) {
                                newContent = byStepPackageTexts.map((item): ItineraryContentStep => ({
                                    mode: 'by-step',
                                    content: {
                                        ...item,
                                        interval: [],
                                        id: undefined
                                    }
                                }));
                            }

                            newContent = newContent.concat(
                                flatten(
                                    targetTexts.map((item, index): ItineraryContentStep[] => {
                                        if (index === options.index - 1) {
                                            return [
                                                {
                                                    mode: 'by-step',
                                                    content: item
                                                },
                                                ...byStepPackageTexts.map((item): ItineraryContentStep => ({
                                                    mode: 'by-step',
                                                    content: {
                                                        ...item,
                                                        interval: []
                                                    }
                                                }))
                                            ]
                                        }
                                        return [
                                            {
                                                mode: 'by-step',
                                                content: item
                                            }
                                        ];
                                    })
                                )
                            ).map((step, index) => {
                                if (step.mode === 'by-step') {
                                    return {
                                        mode: 'by-step',
                                        content: {
                                            ...step.content,
                                            order: index + 1
                                        }
                                    }
                                }
                                return step;
                            });

                            // patch texts and let itinerary ui know that texts should be normalized
                            await makeContentUpdateRequest({
                                tripId: parseInt(GetCookie('trip_id') ?? '0'),
                                version: parseInt(GetCookie('trip_id_version') ?? '0'),
                                items: {
                                    mode: 'by-step',
                                    content: targetTexts
                                },
                                steps: newContent
                            });
                            dispatch(setShouldNormalizeTexts(true));
                        } else {
                            enqueueSnackbar(t('itinerary.bloc-has-no-by-step-texts'), { variant: 'warning' });
                        }
                    }
                }

                dispatch(
                    addBlock({
                        block,
                        variant: options.type === 'circuit' ?
                            options.circuitVariant :
                            null,
                        date: options.type === 'circuit' ?
                            options.circuitDate :
                            'PERIOD',
                        startDate: steps.length > 0 ?
                            firstBlockStep.start_date :
                            tripStartDate,
                        index: options.index
                    })
                );

                if (options.type === 'circuit' && options.adalteRooms) {
                    dispatch(
                        setAdalteModalAddOptions(null)
                    );
                }

                return true;
            }

            return false;
        },
        [
            t,
            dispatch,
            enqueueSnackbar,
            tripStartDate,
            tripEndDate,
            steps,
            tripAllData,
            getDays,
            getCorrespondingPricePeriod
        ]
    );
}
