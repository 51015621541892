//---- Dependencies ----//
import localeText from "../Functions/localeText";

let GoogleMapsLoader = require('google-maps');

import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Popper from '@material-ui/core/Popper';
import Switch from '@material-ui/core/Switch';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import WarningAmber from '@mui/icons-material/WarningAmberOutlined';

import {
    Checkbox,
    FormControlLabel
} from '@mui/material';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DatePicker, PickersDay, LocalizationProvider, DateRangePicker, DateRange, DateRangeDelimiter } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import i18n from '../../i18n.jsx';
import CheckBeforeRequest from '../Common/CheckBeforeRequest.js';
import Axios from 'axios';
import clsx from 'clsx';
import axios from "axios";
import GetCookie from "../Common/Functions/GetCookie";
import moment from "moment";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;
let source = null;

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    dialogSize: {
        "& .mui-jss-MuiDialog-paper": {
            width: 500
        },
        "& .mui-jss-MuiDialog-paperWidthSm": {
            maxWidth: 500
        }
    },
    formControlRoot: {
        //"&& .mui-jss-MuiOutlinedInput-input": {
        //padding: '7px 10px'
        //},
        //"&& .mui-jss-MuiOutlinedInput-root": {
        //borderRadius: 8
        //},
        //"&& .mui-jss-MuiSvgIcon-root" : {
        //color: "#0000008A"
        //},
        //"& .mui-jss-MuiFormLabel-root": {
        //fontWeight: "bold",
        //color: "#0000008A",
        //fontSize: 12,
        //transform: 'translate(20px, 10px) scale(1)',
        //},
        //"& .mui-jss-MuiInputLabel-shrink": {
        //transform: 'translate(14px, -6px) scale(0.75)'
        //},
    },
    formControlRoot1: {
        //"&& .mui-jss-MuiOutlinedInput-input": {
        //padding: '7px 10px'
        //},
        //"&& .mui-jss-MuiOutlinedInput-root": {
        //borderRadius: 8
        //},
        //"&& .mui-jss-MuiSvgIcon-root" : {
        //color: "#0000008A"
        //},
        //"& .mui-jss-MuiFormLabel-root": {
        //fontWeight: "bold",
        //color: "#0000008A",
        //fontSize: 12,
        //transform: 'translate(20px, 10px) scale(1)',
        //},
        //"& .mui-jss-MuiInputLabel-shrink": {
	    //transform: 'translate(14px, -6px) scale(0.75)'
	    //},
    },
    formControlRoot2: {
        //"&& .mui-jss-MuiSvgIcon-root" : {
        //color: "#0000008A"
        //},
	    //"& .mui-jss-MuiInputLabel-outlined": {
	    //transform: 'translate(20px, 11px) scale(1)',
        //fontSize: 12,
        //fontWeight: 500,
        //color: "#0000008A"
	    //},
	    //"& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    //transform: 'translate(14px, -6px) scale(0.75)'
	    //},
	    //"& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
	    //padding: 5
	    //},
	    //"& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd" : {
        //fontSize: 12,
        //borderRadius: 8
	    //},
        //"&& .mui-jss-MuiAutocomplete-input": {
        //padding: "3.5px 4px"
        //}
  	},
    highlight: {
        "borderRadius": "50%",
        //opacity: '30%',
        "backgroundColor": theme.palette.primary.main,
        "color": "white !important",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark
        }
    },
    selectedDate: {
        "borderRadius": "50%",
        //opacity: '30%',
        "backgroundColor": "#ee7203 !important",
        "color": "white !important",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark
        }
    },
    downWidth: {
        width: 300
    },
    fullWidth: {
        width: "100%"
    },
    bold: {
        fontWeight: 'bold'
    }
}));
const MaterialTripInfo = ({ isFrom, pgiRef, tripName, setAgencyFilter, agencyFilter, setPgiRef, setTripName, setNote, openDatePicker, setOpenDatePicker,
    startDate, rangeDate, setStartDate, setRangeDate, currency, setCurrency, setBudget, dateError, dateErrorStart, dateErrorEnd, variantToShow,
    versionToShow, startDestination, setStartDestination, returnDestination, setReturnDestination, selectNight, setSelectNight, setTripType, trip_type, selectDate, setSelectDate, allowedDepartureAirport, allowedArrivalAirport, disallowedDepartureAirport, disallowedArrivalAirport,
    startAirport, setStartAirport, arrivalAirport, setArrivalAirport, cabin, setCabin, allowedCabin, currentFlightCircuit, isHoneymoon, setIsHoneymoon, isWeddingAnniversary, setIsWeddingAnniversary, travelExchangePrices }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const user = useSelector(store => store.user.user);
    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data);
    const itinerary_type_trip_data = useSelector(store => store.itinerary_type.itinerary_type_trip_data);
    const price_data = useSelector(store => store.itinerary_type.price_data);
    const type = useSelector(store => store.itinerary_type.type);
    const currency_list = useSelector(store => store.base.currency_list);
    const map = useSelector((state) => state.itinerary.map);
    const locales = useSelector(state => state.user.locales);
    const current_locale = locales.find((el) => {return el.language_code === i18n.language});

    const [agenciesOption, setAgenciesOption] = useState([]);
    const [open, setOpen] = useState(false);
    const [newDate, setNewDate] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [startDestinationOptions, setStartDestinationOptions] = useState([]);
    const [returnDestinationOptions, setReturnDestinationOptions] = useState([]);
    const [startAirportInput, setStartAirportInput] = useState('');
    const [arrivalAirportInput, setArrivalAirportInput] = useState('');
    const [trip_type_options, setTripTypeOptions] = useState([]);
    const [startAirportLoading, setStartAirportLoading] = useState(false);
    const [arrivalAirportLoading, setArrivalAirportLoading] = useState(false);
    const [google, setGoogle] = useState(null);
    const [returnSame, setReturnSame] = useState(true);
    const [allTrip, setAllTrip] = useState(true);
    const [countNight, setCountNight] = useState([]);
    const [startAirportResults, setStartAirportResults] = useState([]);
    const [arrivalAirportResults, setArrivalAirportResults] = useState([]);
    let version = null;
    if (itinerary_type_details_data !== null && itinerary_type_details_data.data !== undefined) {
        version = itinerary_type_details_data.data.find(data => data.id === versionToShow);
    } else if (itinerary_type_trip_data !== null && itinerary_type_trip_data.data !== undefined) {
        version = itinerary_type_trip_data.data.find(data => data.id === versionToShow);
    }
    const loading = open && agenciesOption.length === 0;
    let minDate = moment().add(1, 'days');
    const PopperMy = function (props) {
  	  return (<Popper {...props} style={downDesktop ? { width: '100%' } : { width: 600 }} placement="bottom-start" />);
  	};
    moment.updateLocale(i18n.language, {
        week: {
            dow: 1
        }
    });
    useEffect(() => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/roadbook-picture-type/`,
        }).then((response) => {
            setTripTypeOptions(response.data.results);
        }).catch((error) => {
            console.log(error);
        });
        if (google === null) {
            let key = "";
            const config = JSON.parse(localStorage.getItem("config"));
            for (let i = 0; i < config.keys.length; i++) {
			    if (config.keys[i].identifier === "google_api") {
			        key = config.keys[i].value;
			    }
            }
            GoogleMapsLoader.KEY = key;
            GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
            GoogleMapsLoader.LANGUAGE = "fr";
            GoogleMapsLoader.load(function (google) {
                setGoogle(google);
            });
        }
    }, []);
    useEffect(() => {
        if (itinerary_type_details_data !== undefined && itinerary_type_details_data !== null) {
            if (itinerary_type_details_data.list_accepted_nb_days !== undefined && itinerary_type_details_data.list_accepted_nb_days !== null && itinerary_type_details_data.list_accepted_nb_days.length !== 0) {
                setCountNight(itinerary_type_details_data.list_accepted_nb_days);
            } else if (itinerary_type_details_data.min_nb_days !== undefined && itinerary_type_details_data.min_nb_days !== null && itinerary_type_details_data.max_nb_days !== undefined && itinerary_type_details_data.max_nb_days !== null) {
                let nb_night = [];
                for (let i = itinerary_type_details_data.min_nb_days; i <= itinerary_type_details_data.max_nb_days; i++) {
                    nb_night.push(i);
                }
                setCountNight(nb_night);
            }
        }
    }, [itinerary_type_details_data]);
    useEffect(() => {
        if (price_data.length !== 0 && ['circuit', 'package'].includes(type)) {
            if (!itinerary_type_details_data.select_date_by_datepicker) {
                let new_date = [];
                console.log('price_data:', price_data);
                price_data.map((data) => {
                    if ((data.variant !== undefined && data.variant === variantToShow)) {
                        if (data.dates === undefined) {
                            if (moment(data.start_date).isSame(moment(data.end_date))) {
                                if (moment(data.start_date).isSameOrAfter(moment())) {
                                    for (let d = moment(data.start_date); d.isSameOrBefore(data.end_date); d.add(1, 'days')) {
                                        if (new_date.find((el) => el.label === moment(d)) === undefined) {
                                            new_date.push({
                                                label: moment(d)
                                            });
                                        }
                                    }
                                }
                            } else if (new_date.find((el) => el.label === moment(data.start_date)) === undefined && moment(data.start_date).isSameOrAfter(moment())) {
                                new_date.push({
                                    periods: data.periods,
                                    label: moment(data.start_date)
                                });
                            }
                        } else {
                            data.dates.map((dates) => {
                                if (dates.variant === variantToShow) {
                                    if (!moment(dates.start_date).isSame(moment(dates.end_date))) {
                                        if (moment(dates.start_date).isSameOrAfter(moment())) {
                                            for (let d = moment(dates.start_date); d.isSameOrBefore(dates.end_date); d.add(1, 'days')) {
                                                if (new_date.find((el) => el.label === moment(d)) === undefined) {
                                                    new_date.push({
                                                        label: moment(d)
                                                    });
                                                }
                                            }
                                        }
                                    } else if (new_date.find((el) => el.label === moment(dates.start_date)) === undefined && moment(dates.start_date).isSameOrAfter(moment())) {
                                        new_date.push({
                                            periods: data.periods,
                                            label: moment(dates.start_date)
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
                let tmp_new_date = new_date.sort((a, b) => {
                    let dateA = new Date(a.label);
                    let dateB = new Date(b.label);
                    return dateA - dateB;
                });
                setNewDate(tmp_new_date);
            } else {
                let filter_data = price_data.filter(data => data.variant === variantToShow && (moment(data.start_date).isSameOrAfter(moment()) || moment(data.end_date).isSameOrAfter(moment())));
                setFilterData(filter_data);
            }
        }
    }, [price_data]);

    useEffect(() => {
        if (!open) {
            setAgenciesOption([]);
        }
    }, [open]);
    useEffect(() => {
        if (startAirportInput.length >= 3) {
            searchAirport('start');
            setStartAirportLoading(true);
        }
    }, [startAirportInput]);
    useEffect(() => {
        if (arrivalAirportInput.length >= 3) {
            searchAirport('arrival');
            setArrivalAirportLoading(true);
        }
    }, [arrivalAirportInput]);
    const getAgencies = (search) => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check && search.length > 0) {
            Axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/children/?type=1,2&search=${search}&limit=250&is_active=true`
            }).then(function (response) {
                let data = response.data.results.slice();
                data.forEach(element => {
                    element.label = `${element.name}`;
                    if (element.street !== null || element.city !== null) {
                        element.label += " |";
                        if (element.street !== null) {
                            element.label += ` ${element.street}`;
                        }
                        if (element.city !== null) {
                            element.label += `, ${element.city}`;
                        }
                    }
                });
                setAgenciesOption(data);
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
            });
        }
    };
    const renderWeekPickerDay = (date, _selectedDates, Props) => {
 	    let dayIsBetween = false;
        let no_start_date = false;
        const duration = itinerary_type_details_data.duration_day;
 	    const dateClone = moment(date);
        if (filterData.length !== 0) {
            filterData.forEach((data) => {
                if (data.dates !== undefined) {
                    data.dates.forEach((data_date) => {
                        if (data_date.start_date === null || data_date.end_date === null) {
                            no_start_date = true;
                        } else if (!moment(data_date.start_date).isSame(data_date.end_date)) {
                            if (moment(dateClone).isSameOrAfter(moment()) && moment(dateClone).isSameOrAfter(moment(data_date.start_date)) && moment(dateClone).isSameOrBefore(moment(data_date.end_date)) && data_date.start_days !== undefined && (data_date.start_days.length === 0 || data_date.start_days.includes(moment(dateClone).weekday()))) {
                                dayIsBetween = true;
                                // if (moment(dateClone).add(duration, 'days').isSameOrBefore(moment(data_date.end_date))) {
                                //     dayIsBetween = true;
                                // }
                            }
                        } else if (moment(dateClone).isSameOrAfter(moment()) && moment(dateClone).isSame(moment(data_date.start_date))) {
                            dayIsBetween = true;
                        }
                    });
                } else if (data.start_date === null || data.end_date === null) {
                    no_start_date = true;
                } else if (moment(dateClone).isSameOrAfter(moment()) && moment(dateClone).isSameOrAfter(moment(data.start_date)) && moment(dateClone).isSameOrBefore(moment(data.end_date))) {
                    dayIsBetween = true;
                }
            });
        }
        return (
            <PickersDay
                {...Props}
                //disableMargin
                disabled={itinerary_type_details_data !== null && ((type === null || ['iti type'].includes(type)) || (filterData.length === 0 || no_start_date)) ? false : !dayIsBetween}
                className={clsx({
                    //[classes.bold]: dayIsBetween,
                    [classes.highlight]: dayIsBetween,
                    [classes.selectedDate]: _selectedDates.some(selectedDate =>
                        dateClone.isSame(selectedDate, 'day')
                    ),
                })}
            />
        );
  	};
    const onChangeStartDate = event => {
        setStartDate(moment(event.target.value, "DD/MM/YYYY"));
        setSelectDate(event.target.value);
    };
    const onChangeNight = event => {
        setSelectNight(event.target.value);
    };
    const handleStartDestinationChange = (inputValue, type) => {
        if (google !== null) {
            let autocomplete_service = new google.maps.places.AutocompleteService();
            let service_request = {
                input: inputValue,
                types: ['geocode']
            };
            autocomplete_service.getPlacePredictions(service_request, (results) => {
                if (results) {
                    let newOptions = [];
                    console.log('results:', results);
                    results.map((result) => {
                        if (result.types.includes("locality") || result.types.includes("administrative_area_level_1")) {
                            newOptions.push({
                                name: result.description,
                                id: null,
                                reference: result.reference,
                                structured_formatting: result.structured_formatting
                            });
                        }
                    });
                    if (type === 'start') {
                        setStartDestinationOptions(newOptions);
                    } else {
                        setReturnDestinationOptions(newOptions);
                    }
                }
            });
        }
    };
    const handleStartAirportChange = (inputValue) => {
        setStartAirportInput(inputValue);
    };
    const handleArrivalAirportChange = (inputValue) => {
        setArrivalAirportInput(inputValue);
    };
    const handleReturnSame = () => {
        if (returnSame) {
            setReturnDestination(null);
        } else {
            setReturnDestination(startDestination);
        }
        setReturnSame(!returnSame);
    };
    const onChangeClassType = event => {
        setCabin(event.target.value);
    };
    const startAirportInputHandle = (event, value) => {
        if (event !== null && event.type === "change") {
            setStartAirportInput(value);
        }
    };
    const arrivalAirportInputHandle = (event, value) => {
        if (event !== null && event.type === "change") {
            setArrivalAirportInput(value);
        }
    };
    const searchAirport = (type) => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            if (source !== null) {
	    		source.cancel('Operation canceled by the user.');
	    	}
		    source = axios.CancelToken.source();
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}iata-airports/?lang=${i18n.language}&search=${type === 'start' ? startAirportInput : arrivalAirportInput}&limit=15`,
                cancelToken: source.token
            }).then(function (response) {
                let new_result = [];
                response.data.results.map((airport) => {
                    let airport_light = {
                        name: airport.name !== undefined && airport.name !== null ? airport.name : airport.international_name,
                        city: airport.iata_city.name !== undefined && airport.iata_city.name !== null ? airport.iata_city.name : airport.iata_city.international_name,
                        airport_code: airport.airport_code
                    };
                    new_result.push(airport_light);
                });
                if (type === 'start') {
                    let start_airports = [...new_result];
                    if (disallowedArrivalAirport.length !== 0) {
                        start_airports = start_airports.filter(airport => 
                            !disallowedDepartureAirport.some(disallowed => disallowed.airport_code === airport.airport_code)
                        );
                    }
                    setStartAirportResults(start_airports);
                    setStartAirportLoading(false);
                } else {
                    let arrival_airports = [...new_result];
                    if (disallowedArrivalAirport.length !== 0) {
                        arrival_airports = arrival_airports.filter(airport => 
                            !disallowedArrivalAirport.some(disallowed => disallowed.airport_code === airport.airport_code)
                        );
                    }
                    setArrivalAirportResults(arrival_airports);
                    setArrivalAirportLoading(false);
                }
            }).catch(function (error) {
                if (axios.isCancel(error)) {
				    console.log('Request canceled', error.message);
                } else {
                    setStartAirportLoading(false);
                    console.log(error.response);
                }
            });
        }
    };
    return (
        <Grid container direction={"column"} spacing={2}>
            {
                (user.client_full.type !== 2 && !['cercledesvoyages'].includes(quotation_code)) && (
                    <Fragment>
                        <Grid item style={{ width: "100%" }}>
                            <Typography variant={"h6"} className={classes.genericText}> { isFrom === "TripList" ? t("menu.trip_list.change_client") : t("menu.trip_list.chose_client") }</Typography>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <Autocomplete
                                fullWidth
                                freeSolo
                                options={agenciesOption}
                                onChange={(event, selectedOptions) => {
                                    setAgencyFilter(selectedOptions);
                                }}
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                loading={loading}
                                onInputChange={(event, value) => {
                                    getAgencies(value);
                                }}
                                //style={{width: "50%"}}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.name === value.name}
                                // filterOptions={(options, state) => options}
                                value={agencyFilter}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={`${classes.formControlRoot2}`}
                                        variant={"outlined"}
                                        label={t("menu.margin.agency")}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Fragment>
                )
            }
            {
                user.client_full.type === 2 && user.client_list !== null && user.client_list.length > 0 && !['cercledesvoyages'].includes(quotation_code) && (
                    <Fragment>
                        <Grid item style={{ width: "100%" }}>
                            <Typography variant={"h6"} className={classes.genericText}> { isFrom === "TripList" ? t("menu.trip_list.change_client") : t("menu.trip_list.chose_client") }</Typography>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <Autocomplete
                                fullWidth
                                freeSolo
                                options={user.client_list}
                                onChange={(event, selectedOptions) => {
                                    setAgencyFilter(selectedOptions);
                                }}
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                loading={loading}
                                //onInputChange={(event, value) => {getAgencies(value)}}
                                //style={{width: "50%"}}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.name === value.name}
                                filterOptions={(options, state) => options}
                                value={agencyFilter === null ? user.client_full : agencyFilter}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={`${classes.formControlRoot2}`}
                                        variant={"outlined"}
                                        label={t("menu.margin.agency")}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Fragment>
                )
            }
            <Grid item style={{ width: "100%" }}>
                <Typography variant={"h6"} className={classes.genericText}> { t("menu.trip_list.more_info_on_duplicate") }</Typography>
            </Grid>
            {
                !['cercledesvoyages'].includes(quotation_code) && (
                    <Grid item style={{ width: "100%" }}>
                        <TextField
                            style={{ width: !downDesktop ? 300 : "100%" }}
                            variant={"outlined"}
                            className={`${classes.formControlRoot1}`}
                            value={pgiRef}
                            onChange={(e) => {
                                setPgiRef(e.target.value);
                            }}
                            label={ quotation_code !== "marcovasco" ? t("menu.trip_list.pgi") : t("menu.trip_list.sugar") }
                        />
                    </Grid>
                )
            }
            <Grid item style={{ width: "100%" }}>
                <TextField
                    fullWidth={!downDesktop}
                    style={{}}
                    variant={"outlined"}
                    value={tripName}
                    className={`${classes.formControlRoot1} ${classes.fullWidth}`}
                    onChange={(e) => {
                        setTripName(e.target.value);
                    }}
                    label={ t("cart.trip_name") }
                />
            </Grid>
            {
                isFrom === "createNewTrip" && !['cercledesvoyages'].includes(quotation_code) && (
                    <Grid item style={{ width: "100%" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    options={currency_list}
                                    onChange={(event, selectedOptions) => {
                                        setCurrency(selectedOptions);
                                    }}
                                    getOptionLabel={(option) => `${option.international_name} (${option.iso_code})`}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    value={currency}
                                    disabled={ quotation_code === 'verdie' }
                                    renderInput={(params) => (
                                        <TextField {...params} className={`${classes.formControlRoot2}`} variant="outlined" label={t("global.currency")} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    variant={"outlined"}
                                    className={`${classes.formControlRoot1}`}
                                    onChange={(e) => {
                                        setBudget(e.target.value);
                                    }}
                                    label={ t("global.budget") + " " + t('global.optional') }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {
                isFrom === "createNewTrip" && ['cercledesvoyages', 'tripadekua'].includes(quotation_code) && (
                    <Grid item style={{ width: "100%" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    options={trip_type_options}
                                    onChange={(event, selectedOptions) => {
                                        setTripType(selectedOptions);
                                    }}
                                    getOptionLabel={(option) => localeText(current_locale.id, option.localization, '', 'name')}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    value={trip_type}
                                    renderInput={(params) => (
                                        <TextField {...params} className={`${classes.formControlRoot2}`} variant="outlined" label={t("global.trip_type")} />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {/*<Grid item>
                <TextField
                    fullWidth
                    className={classes.formControlRoot1}
                    variant={"outlined"}
                    multiline
                    rows={2}
                    onChange={(e) => {setNote(e.target.value)}}
                    label={ t("placeholder.custom_information") }
                />
            </Grid> */}
            <Grid item style={{ width: "100%" }}>
                <Typography variant={"h6"} className={classes.genericText}> { isFrom === "TripList" ? t("menu.trip_list.change_dep_date") : t("menu.trip_list.new_dep_date") }</Typography>
            </Grid>
            <Grid item style={{ width: "100%" }}>
                <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ i18n.language }>
                    {
                        //isFrom !== "createNewTrip" &&
                        isFrom !== "createNewTrip" && itinerary_type_details_data !== null && ((type === '' || ['iti type'].includes(type)) || itinerary_type_details_data.select_date_by_datepicker) && (
                            <DatePicker
                                label={t("home.modal.start_date_placeholder")}
                                value={startDate}
                                //allowSameDateSelection={true}
                                disableOpenPicker={true}
                                minDate={new Date()}
                                open={openDatePicker}
                                onChange={(newValue) => setStartDate(newValue)}
                                onClose={ () => {
                                    setOpenDatePicker(false);
                                }}
                                views={["year", "month", "date"]}
            			      	renderDay={renderWeekPickerDay}
                                renderInput={(props) =>
                                    (<TextField
                                        onClick={() => {
                                            setOpenDatePicker(!openDatePicker);
                                        }}
                                        {...props}
                                        variant={"outlined"}
                                        className={classes.formControlRoot}
                                        helperText={""}
                                        error={false}
                                        style={{ width: !downDesktop ? 300 : "100%" }}
                                    />)
                                }
                            />
                        )
                    }
                    {
                        isFrom !== "createNewTrip" && itinerary_type_details_data !== null && ['circuit', 'package'].includes(type) && !itinerary_type_details_data.select_date_by_datepicker &&
                        <FormControl variant={"outlined"} style={{ minWidth: 230 }}>
                            <InputLabel id={"date-select-label"}>{t('global.date_selection')}</InputLabel>
                            <Select
                                labelId={"date-select-label"}
                                id={"date-select"}
                                value={selectDate}
                                onChange={onChangeStartDate}
                                label={t('global.date_selection')}
                                error={dateError}
                            >
                                {
                                    newDate.map((data) => {
                                        // let current_price = price_data.find(price => data.periods === price.periods);
                                        // let ref_price = current_price?.terrestrial_price?.find(price => price.is_price_reference);
                                        return (
                                            <MenuItem key={data.label} value={data.label}>
                                                {`${moment(data.label).format('L')}`}
                                                {
                                                    travelExchangePrices !== undefined && travelExchangePrices !== null && travelExchangePrices.length !== 0 && travelExchangePrices[0].on_request &&
                                                    <Tooltip title={t('poi.on_demand')}>
                                                        <WarningAmber style={{ marginLeft: '5px', fontSize: '20px', verticalAlign: 'bottom'}}/>
                                                    </Tooltip>
                                                }
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    }
                    {
                        isFrom === "createNewTrip" && (
                            <DateRangePicker
                                startText={t("header.responsive.start")}
                                endText={t("header.responsive.end")}
                                value={rangeDate}
                                calendars={2}
                                minDate={minDate}
                                onChange={(newValue) => setRangeDate(newValue)}
                                renderInput={(startProps, endProps) => {
                                    startProps.helperText = undefined;
                                    endProps.helperText = t("itineraryType.end_date_helper");
                                    endProps.inputProps.onClick = endProps.inputProps.onFocus;
                                    return (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    className={classes.formControlRoot}
                                                    variant={"outlined"}
                                                    style={{ width: !downDesktop ? 300 : "100%" }}
                                                    {...startProps}
                                                    error={dateErrorStart}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    className={classes.formControlRoot}
                                                    variant={"outlined"}
                                                    style={{ width: !downDesktop ? 300 : "100%" }}
                                                    {...endProps}
                                                    error={dateErrorEnd}
                                                />
                                            </Grid>
                                        </Grid>
                                    );
                                }
                                }
                            />
                        )
                    }
                </LocalizationProvider>
            </Grid>
            {
                // Select night for package
                countNight.length !== 0 && (
                    <Fragment>
                        <Grid item>
                            <Typography variant={"h6"} className={classes.genericText}> { t("shared.night(s)") }</Typography>
                        </Grid>
                        <Grid item>
                            <FormControl variant={"outlined"} style={{ minWidth: 230 }}>
                                <InputLabel id={"night-select-label"}>{t('global.nights')}</InputLabel>
                                <Select
                                    labelId={"night-select-label"}
                                    id={"night-select"}
                                    value={selectNight}
                                    onChange={onChangeNight}
                                    label={t('global.nights')}
                                >
                                    {
                                        countNight.map((night, night_index) => {
                                            return (
                                                <MenuItem key={night_index} value={night}>{night}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Fragment>
                )
            }
            {
                isFrom !== "createNewTrip" && itinerary_type_details_data !== null && ['circuit', 'package', 'iti type'].includes(type) && (
                //((version !== undefined && version !== null && !version.has_trip_starting_point && isFrom !== "createNewTrip" && itinerary_type_details_data !== null && ['circuit', 'package', 'iti type'].includes(type)) || isFrom === "createNewTrip") && (
                    <Fragment>
                        <Grid item>
                            <Typography variant={"h6"} className={classes.genericText}> { `${t('itineraryType.choose-start-destination')} ${t('global.optional')}` }</Typography>
                            {/* <Typography variant={"h6"} className={classes.genericText}> { t('itinerary.add_step') }</Typography> */}
                        </Grid>
                        {/* <Grid item>
                            <Grid container alignItems={'center'}>
                                <Grid item>
                                    <Typography
                                        component="div"
                                        sx={{
                                            opacity: 0.54,
                                            minWidth: 90,
                                            // maxWidth: 90,
                                            textTransform: 'uppercase',
                                            fontSize: 14,
                                            fontWeight: 700
                                        }}
                                    >
                                        {t('itineraryType.full-trip')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        checked={!allTrip}
                                        onChange={() => setAllTrip(!allTrip)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        component="div"
                                        sx={{
                                            opacity: 0.54,
                                            minWidth: 90,
                                            // maxWidth: 90,
                                            textTransform: 'uppercase',
                                            fontSize: 14,
                                            fontWeight: 700
                                        }}
                                    >
                                        {t('itineraryType.terrestrial-only')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {
                            //allTrip && (
                            <Fragment>
                                <Grid item style={{width: "100%"}}>
                                    <Typography variant={"h7"} className={classes.genericText}> { t('itineraryType.choose-start-destination') }</Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox checked={returnSame} onChange={handleReturnSame} sx={{
                                            'color': `${main_color}`,
                                            '&.Mui-checked': {
                                                color: `${main_color}`
                                            }
                                        }}/>}
                                        label={t('itinerary.return_same')}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        id={`google-maps-start-destination`}
                                        options={startDestinationOptions}
                                        getOptionLabel={(option) => option.name }
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        freeSolo
                                        selectOnFocus
                                        clearOnBlur
                                        autoComplete={false}
                                        filterOptions={(options, object) => options}
                                        value={startDestination}
                                        onChange={(event, newValue) => {
                                            setStartDestination(newValue);
                                            if (returnSame) {
                                                setReturnDestination(newValue);
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            handleStartDestinationChange(newInputValue, 'start');
                                        }}
                                        PopperComponent={PopperMy}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('itinerary.departure_city')}
                                                variant={"outlined"}
                                                InputProps={{
                                                    ...params.InputProps
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {
                                    !returnSame && (
                                        <Grid item>
                                            <Autocomplete
                                                id={`google-maps-return-destination`}
                                                options={returnDestinationOptions}
                                                getOptionLabel={(option) => option.name }
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                freeSolo
                                                selectOnFocus
                                                clearOnBlur
                                                autoComplete={false}
                                                filterOptions={(options, object) => options}
                                                value={returnDestination}
                                                onChange={(event, newValue) => {
                                                    setReturnDestination(newValue);
                                                }}
                                                onInputChange={(event, newInputValue) => {
                                                    handleStartDestinationChange(newInputValue, 'return');
                                                }}
                                                PopperComponent={PopperMy}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('itinerary.return_city')}
                                                        variant={"outlined"}
                                                        InputProps={{
                                                            ...params.InputProps
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    currentFlightCircuit !== null &&
                                    <>
                                        <Grid item>
                                            <Typography variant={"h6"} className={classes.genericText}> { `${t('itineraryType.choose-departure-arrival-airport')} ${t('global.optional')}` }</Typography>
                                        </Grid>
                                        <Grid item>
                                            {
                                                allowedDepartureAirport.length > 0 &&
                                                <Autocomplete
                                                    id={`choose-start-airport`}
                                                    options={allowedDepartureAirport}
                                                    getOptionSelected={(option, value) => option.airport_code === value.airport_code}
                                                    getOptionLabel={(option) => {
                                                        if (option === "") {
                                                            return "";
                                                        }
                                                        return `${option.iata_city.name !== undefined && option.iata_city.name !== null ? option.iata_city.name : option.iata_city.international_name}, ${option.name !== undefined && option.name !== null ? option.name : option.international_name} (${option.airport_code})`;
                                                    }}
                                                    autoComplete={false}
                                                    value={startAirport}
                                                    onChange={(event, newValue) => {
                                                        setStartAirport(newValue);
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        handleStartAirportChange(newInputValue);
                                                    }}
                                                    inputValue={startAirportInput}
                                                    PopperComponent={PopperMy}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t('itineraryType.start-airport')}
                                                            variant={"outlined"}
                                                            InputProps={{
                                                                ...params.InputProps
                                                            }}
                                                        />
                                                    )}
                                                />
                                            }
                                            {
                                                allowedDepartureAirport.length === 0 &&
                                                <Autocomplete
                                                    id={`choose-start-airport-search`}
                                                    getOptionSelected={(option, value) => option.airport_code === value.airport_code}
                                                    getOptionLabel={(option) => {
                                                        if (option === "") {
                                                            return "";
                                                        } 
                                                        return (option.city + ", " + option.name + " (" + option.airport_code + ")");
                                                    }}
                                                    onInputChange={startAirportInputHandle}
                                                    onChange={((event, value) => {
                                                        setStartAirport(value);
                                                    })}
                                                    value={startAirport}
                                                    noOptionsText={t("flight_groups.no_airport_found")}
                                                    options={startAirportResults}
                                                    loading={startAirportLoading}
                                                    loadingText={t('accommodation.loading')}
                                                    className={classes.autoComplete}
                                                    renderInput={(props) => (
                                                        <TextField 
                                                            {...props}
                                                            InputProps={{
                                                                ...props.InputProps
                                                            }}
                                                            inputProps={{
                                                                ...props.inputProps,
                                                                // disable autocomplete and autofill
                                                                autoComplete: 'off' 
                                                            }}
                                                            label={t('itineraryType.start-airport')}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            }
                                        </Grid>
                                        <Grid item>
                                            {
                                                allowedArrivalAirport.length > 0 &&
                                                <Autocomplete
                                                    id={`choose-arrival-airport`}
                                                    options={allowedArrivalAirport}
                                                    getOptionSelected={(option, value) => option.airport_code === value.airport_code}
                                                    getOptionLabel={(option) => {
                                                        if (option === "") {
                                                            return "";
                                                        }
                                                        return `${option.iata_city.name !== undefined && option.iata_city.name !== null ? option.iata_city.name : option.iata_city.international_name}, ${option.name !== undefined && option.name !== null ? option.name : option.international_name} (${option.airport_code})`;
                                                    }}
                                                    autoComplete={false}
                                                    value={arrivalAirport}
                                                    onChange={(event, newValue) => {
                                                        setArrivalAirport(newValue);
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        handleArrivalAirportChange(newInputValue);
                                                    }}
                                                    inputValue={arrivalAirportInput}
                                                    PopperComponent={PopperMy}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t('itineraryType.arrival-airport')}
                                                            variant={"outlined"}
                                                            InputProps={{
                                                                ...params.InputProps
                                                            }}
                                                        />
                                                    )}
                                                />
                                            }
                                            {
                                                allowedArrivalAirport.length === 0 &&
                                                <Autocomplete
                                                    id={`choose-arrival-airport-search`}
                                                    getOptionSelected={(option, value) => option.airport_code === value.airport_code}
                                                    getOptionLabel={(option) => {
                                                        if (option === "") {
                                                            return "";
                                                        } 
                                                        return (option.city + ", " + option.name + " (" + option.airport_code + ")");
                                                    }}
                                                    onInputChange={arrivalAirportInputHandle}
                                                    onChange={((event, value) => {
                                                        setArrivalAirport(value);
                                                    })}
                                                    value={arrivalAirport}
                                                    noOptionsText={t("flight_groups.no_airport_found")}
                                                    options={arrivalAirportResults}
                                                    loading={arrivalAirportLoading}
                                                    loadingText={t('accommodation.loading')}
                                                    className={classes.autoComplete}
                                                    renderInput={(props) => (
                                                        <TextField 
                                                            {...props}
                                                            InputProps={{
                                                                ...props.InputProps
                                                            }}
                                                            inputProps={{
                                                                ...props.inputProps,
                                                                // disable autocomplete and autofill
                                                                autoComplete: 'off' 
                                                            }}
                                                            label={t('itineraryType.arrival-airport')}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            }
                                        </Grid>
                                        {
                                            startAirport !== null && allowedCabin.length > 1 &&
                                            <Grid item>
                                                <FormControl variant={"outlined"} style={{ minWidth: 230 }}>
                                                    <InputLabel id={"cabin-select-label"}>{t('flight_groups.class_type')}</InputLabel>
                                                    <Select
                                                        labelId={"cabin-select-label"}
                                                        id={"cabin-select"}
                                                        value={cabin}
                                                        onChange={onChangeClassType}
                                                        label={t('flight_groups.class_type')}
                                                    >
                                                        {
                                                            allowedCabin.map((data) => {
                                                                return (
                                                                    <MenuItem key={data} value={data}>{t(`flight_search.cabin_class.${data.toLowerCase()}`)}</MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        }
                                    </>
                                }
                            </Fragment>
                            //)
                        }
                    </Fragment>
                )
            }
        </Grid>
    );
};
export default React.memo(MaterialTripInfo);
