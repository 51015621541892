// Dependencies
import React, { Fragment } from 'react';
// Core & Lab
import Typography from '@material-ui/core/Typography';

const LocaleTextArray = ({localization, default_text, text_key, main_locale}) => {
	let displayed_text = default_text;
	for (let i = 0; i < localization.length; i++) {
		if (localization[i].locale === main_locale) {
			if (!!localization[i][text_key]) {
				displayed_text = localization[i][text_key];
			}
		}
	}
	if (displayed_text === null) {
		return null;
	} else if (displayed_text.length === 0) {
		return <Fragment>-</Fragment>;
	}
	return <Fragment>{ displayed_text.map(text => <Typography>{ text }</Typography>) }</Fragment>;
};

export default LocaleTextArray;