import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Chip,
    Collapse,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {
    BuildCircle,
    CloudCircle,
    CloudOff,
    ErrorOutline,
    MoreVert,
    ReportProblem,
    SupportAgentOutlined
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import axios from "axios";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItem } from "./CartConstructionProductsTableItem";
import { CartConstructionProductsTableItemMenu } from "./CartConstructionProductsTableItemMenu";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from "./CartConstructionProductsTableItemProviderQuotationStatus";
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from "./CartPackageItemChangeProviderQuotationStatusButtons";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { useGetPrice } from "./utils/getPrice";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useProductDays } from "./utils/productDays";
import { useManualProductAlerts } from "./utils/manualProductAlerts";
import { useTripDays } from "./utils/tripDays";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import { useShowError } from "../Utils/showError";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { AppState } from "../../Reducers/Reducers";
import { isProductPackaged } from "./utils/isProductPackaged";

type Props = {
    item: ReturnType<typeof useCartProducts>['assistances'][number]
}

export function CartConstructionProductsTableAssistanceItem(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const total_cost = useSelector((state: AppState) => state.trip.total_cost);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const user = useSelector((state: AppState) => state.user.user);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const [openMenu, setOpenMenu] = useState(false);
    const provider = useMemo(() => {
        return manual_providers.find((item) => {
            return item.provider.id === props.item.assistance.provider;
        }) ?? providers.find((item) => {
            return item.provider.id === props.item.assistance.provider;
        });
    }, [providers, manual_providers, props.item]);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const showError = useShowError();
    const update = useCartProductUpdate({
        onError(error) {
            console.error(error);
            showError(error);
        }
    });
    const tripDays = useTripDays();
    const getDays = useProductDays();
    const getPrice = useGetPrice();
    const manualProductAlerts = useManualProductAlerts(
        props.item.type === 'manual' ?
            props.item.assistance :
            null
    );
    const startDate = window.moment.utc(props.item.assistance.start_date);
    const endDate = window.moment.utc(props.item.assistance.end_date);
    const price = getPrice(props.item.assistance.prices);
    const days = getDays(props.item.assistance.start_date, props.item.assistance.end_date);

    const onDeleteTravelerModificationAlert = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        const version = parseInt(GetCookie('trip_id_version') ?? '-1');
        update(
            version,
            {
                id: props.item.assistance.id,
                type: props.item.type === 'normal' ?
                    'assistance' :
                    'manual',
                isCustom: props.item.assistance.is_custom
            },
            { traveler_modification: null }
        );
    };

    const onUpdateAssistance = () => {
        if (props.item.type === 'normal') {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${["gritchen", "intermundial"].includes(provider?.provider.code) ? 'insurance' : 'assistance'}/${props.item.assistance.id}/update_booking/`,
                data: props.item.assistance.product_type === 8 ? {
                    contract_reference: props.item.assistance.contract_reference,
                    trip_price: Math.round((((total_cost ?? 0) - getPrice(props.item.assistance.prices).cost) / (travelers?.length ?? 1) * props.item.assistance.group_passenger.travelers.length))
                } : undefined
            }).then(response => {
                dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
                enqueueSnackbar(t<string>('cart-material.assistance-updated'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t<string>('cart-material.assistance-updated-error'), { variant: 'error' });
            });
        }
    };

    const alerts = [
        props.item.assistance.product_type !== 8 &&
        props.item.assistance.booking_status &&
        props.item.assistance.booking_status.status_booking === 'CONFIRMED' &&
        props.item.assistance.booking_process_state !== 'CANCEL' &&
        <Alert
            variant={ 'filled' }
            severity={ 'info' }
            action={
                <Button color={ 'inherit' } size={ 'small' } variant="outlined" onClick={ onUpdateAssistance }>
                    { t<string>('cart-material.assistance-update') }
                </Button>
            }
        >
            <AlertTitle>{ t<string>('global.informations') } :</AlertTitle>
            { t<string>('cart-material.assistance-update-helper') }
        </Alert>,
        props.item.type === 'normal' &&
        props.item.assistance.product_type === 8 &&
        (props.item.assistance.booking_status === null || props.item.assistance.booking_status.status_booking !== "CANCELLED") &&
        (
            props.item.assistance.trip_price > Math.round((((total_cost ?? 0) - getPrice(props.item.assistance.prices).cost) / (travelers?.length ?? 1) * props.item.assistance.group_passenger.travelers.length)) + 1 ||
            props.item.assistance.trip_price < Math.round((((total_cost ?? 0) - getPrice(props.item.assistance.prices).cost) / (travelers?.length ?? 1) * props.item.assistance.group_passenger.travelers.length)) - 1
        ) &&
        <Alert
            variant={ 'filled' }
            severity={ 'info' }
            action={
                props.item.assistance.booking_status === null &&
                <Button color={ 'inherit' } size={ 'small' } variant="outlined" onClick={ onUpdateAssistance }>
                    { t<string>('cart-material.insurance-update') }
                </Button>
            }
        >
            <AlertTitle>{ t<string>('global.informations') } :</AlertTitle>
            { t<string>('cart-material.insurance-update-helper') }
        </Alert>
    ].filter((item): item is Exclude<typeof item, false | null> => !!item).concat(manualProductAlerts);

    return (
        <>
            <CartConstructionProductsTableItem
                {
                    ...(
                        props.item.type === 'normal' ?
                            { type: 'assistance', item: props.item.assistance } :
                            { type: 'manual', item: props.item.assistance }
                    )
                }
            >
                <LightTooltip
                    title={alerts}
                    onClick={(event) => event.stopPropagation()}
                    placement="top"
                    arrow
                >
                    <TableCell sx={{ verticalAlign: 'middle', paddingRight: 0 }}>
                        {
                            alerts.length > 0 &&
                            <ReportProblem color="warning" />
                        }
                    </TableCell>
                </LightTooltip>
                <TableCell color="inherit">
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        flexWrap="nowrap"
                    >
                        <LightTooltip title={t<string>('cart-material.assistance')} placement="top" arrow>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                flexWrap="nowrap"
                            >
                                <SupportAgentOutlined />
                                <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                    sx={{
                                        "display": '-webkit-box',
                                        '-webkit-line-clamp': '1',
                                        '-webkit-box-orient': 'vertical',
                                        "overflow": 'hidden',
                                        "textOverflow": "ellipsis"
                                    }}
                                >
                                    {t<string>('cart-material.assistance')}
                                </Typography>
                            </Stack>
                        </LightTooltip>
                        {
                            isProductPackaged({
                                product: props.item.assistance,
                                stackInfos: trip?.stack_info ?? null,
                                connected: true
                            }) &&
                            (
                                user?.client_full?.type !== 2 ||
                                props.item.assistance.creator?.client?.type === user.client_full.type
                            ) &&
                            <>
                                {
                                    props.item.type === 'normal' &&
                                    !props.item.assistance.is_custom &&
                                    <Chip
                                        label={t<string>('cart-material.cart-construction-flux')}
                                        size="small"
                                        sx={{
                                            backgroundColor: '#2ACAEA',
                                            color: '#fff'
                                        }}
                                        icon={<CloudCircle color="inherit" />}
                                    />
                                }
                                {
                                    props.item.type === 'normal' &&
                                    props.item.assistance.is_custom &&
                                    <Chip
                                        size="small"
                                        label={t<string>('cart-material.cart-construction-offline')}
                                        sx={{
                                            backgroundColor: '#6A329F',
                                            color: '#fff'
                                        }}
                                        icon={<CloudOff color="inherit" />}
                                    />
                                }
                                {
                                    props.item.type === 'manual' &&
                                    <Chip
                                        label={t<string>('accommodation.manual')}
                                        size="small"
                                        sx={{
                                            backgroundColor: '#6A329F',
                                            color: '#fff'
                                        }}
                                        icon={<BuildCircle color="inherit" />}
                                    />
                                }
                            </>
                        }
                    </Stack>
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{
                            position: 'absolute',
                            marginTop: 1,
                            opacity: 0.7
                        }}
                    >
                        <LightTooltip
                            title={
                                (!days[0] || !tripDays.includes(days[0])) ?
                                    t<string>(
                                        'cart-material.cart-construction-incorrect-product-days',
                                        { day: tripDays.join(', ') }
                                    ) :
                                    ''
                            }
                            arrow
                        >
                            <Box
                                sx={
                                    (!days[0] || !tripDays.includes(days[0])) ?
                                        {
                                            backgroundColor: '#ff0000',
                                            color: '#fff'
                                        } :
                                        undefined
                                }
                                component="span"
                            >
                                {
                                    days[0] !== days[days.length - 1] ?
                                        t<string>(
                                            'cart-material.cart-construction-day-from-to',
                                            { from: days[0], to: days[days.length - 1] }
                                        ) :
                                        t<string>(
                                            'cart-material.cart-construction-day',
                                            { day: days[0] }
                                        )
                                }
                            </Box>
                        </LightTooltip>
                        {' '}-{' '}
                        {
                            (startDate.isValid() || endDate.isValid()) &&
                            t<string>(
                                'cart-material.cart-construction-products-table-date',
                                {
                                    from: startDate.isValid() ? startDate.format('L HH:mm') : null,
                                    to: endDate.isValid() ? endDate.format('L HH:mm') : null
                                }
                            )
                        }
                        <Stack
                            display="inline-flex"
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <CartConstructionProductsTableItemProviderQuotationStatus
                                item={props.item.assistance}
                            />
                            <CartPackageItemChangeProviderQuotationStatusButtons
                                item={{
                                    ...props.item.assistance,
                                    type: props.item.type === 'manual' ?
                                        'manual' :
                                        'assistances',
                                    isCustom: props.item.assistance.is_custom
                                }}
                            />
                        </Stack>
                        {
                            props.item.assistance.traveler_modification &&
                            <Chip
                                color="warning"
                                label={
                                    props.item.assistance.traveler_modification === 'SET_IN_TRIP' ?
                                        t<string>('cart-material.add-to-cart-traveler') :
                                        t<string>('cart-material.add-to-option-traveler')
                                }
                                size="small"
                                icon={<ErrorOutline color="inherit" />}
                                onDelete={onDeleteTravelerModificationAlert}
                            />
                        }
                    </Typography>
                </TableCell>
                <TableCell>
                    -
                </TableCell>
                <LightTooltip
                    title={
                        props.item.type === 'normal' ?
                            props.item.assistance.localization.find((item) => {
                                return item.locale === locale;
                            })?.name ??
                            props.item.assistance.contract_name ??
                            t<string>('cart-material.assistance') :
                            props.item.assistance.localization.find((item) => {
                                return item.locale === locale;
                            })?.name ??
                            props.item.assistance.name
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                props.item.type === 'normal' ?
                                    props.item.assistance.localization.find((item) => {
                                        return item.locale === locale;
                                    })?.name ??
                                    props.item.assistance.contract_name ??
                                    t<string>('cart-material.assistance') :
                                    props.item.assistance.localization.find((item) => {
                                        return item.locale === locale;
                                    })?.name ??
                                    props.item.assistance.name
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <TableCell>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {
                            !isProductPackaged({
                                product: props.item.assistance,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            <CartProductCardProviderLogo
                                logoUrl={
                                    provider?.provider.logo?.url ??
                                    provider?.provider.logo?.thumbnail_big ??
                                    provider?.provider.logo?.thumbnail_medium ??
                                    provider?.provider.logo?.thumbnail_little ??
                                    null
                                }
                                name={provider?.provider.name ?? null}
                                width={48}
                                height={32}
                            />
                        }
                    </Stack>
                </TableCell>
                <LightTooltip
                    title={
                        props.item.assistance.booking_status?.item_reference ?
                            t<string>(
                                'cart-material.cart-construction-reference',
                                { ref: props.item.assistance.booking_status.item_reference }
                            ) :
                            ''
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                props.item.assistance.booking_status?.item_reference &&
                                t<string>(
                                    'cart-material.cart-construction-reference',
                                    { ref: props.item.assistance.booking_status.item_reference }
                                )
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <TableCell sx={{ maxWidth: 'none !important', textAlign: 'center' }}>
                    {
                        !isProductPackaged({
                            product: props.item.assistance,
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        }) &&
                        <>
                            {
                                props.item.type === 'normal' ?
                                    <CartConstructionProductsTableItemStatus
                                        type="assistance"
                                        item={props.item.assistance}
                                        bookingProcessState={props.item.assistance.booking_process_state}
                                        bookingStatus={props.item.assistance.booking_status}
                                        agencyNeedToBook={props.item.assistance.agency_need_to_book}
                                    /> :
                                    <CartConstructionProductsTableItemStatus
                                        type="manual"
                                        item={props.item.assistance}
                                        bookingProcessState={props.item.assistance.booking_process_state}
                                        bookingStatus={props.item.assistance.booking_status}
                                        agencyNeedToBook={props.item.assistance.agency_need_to_book}
                                    />
                            }
                        </>
                    }
                </TableCell>
                <TableCell>
                    <div className="ellipsis">
                        {
                            !isProductPackaged({
                                product: props.item.assistance,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            new Intl.NumberFormat(
                                language,
                                {
                                    style: 'currency', 
                                    currency: price.currency?.iso_code ?? 'EUR'
                                }
                            ).format(price.cost)
                        }
                    </div>
                </TableCell>
                <TableCell>
                    <IconButton
                        ref={menuButtonRef}
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation();
                            setOpenMenu(true);
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    {
                        props.item.type === 'normal' &&
                        <CartConstructionProductsTableItemMenu
                            type="assistance"
                            open={openMenu}
                            anchorEl={menuButtonRef.current}
                            value={props.item.assistance}
                            onClose={() => setOpenMenu(false)}
                        />
                    }
                    {
                        props.item.type === 'manual' &&
                        <CartConstructionProductsTableItemMenu
                            type="manual"
                            open={openMenu}
                            anchorEl={menuButtonRef.current}
                            value={props.item.assistance}
                            onClose={() => setOpenMenu(false)}
                        />
                    }
                </TableCell>
            </CartConstructionProductsTableItem>
            <TableRow className="cart-construction-table-item-margin-row">
                <MarginWrapper item={props.item} />
            </TableRow>
        </>
    );
}

type MarginWrapperProps = {
    item: ReturnType<typeof useCartProducts>['assistances'][number]
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <TableCell
            sx={
                seeAllProductsMargins ?
                    undefined :
                    { paddingTop: 0, paddingBottom: 0 }
            }
            colSpan={20}
        >
            <Collapse
                in={
                    seeAllProductsMargins &&
                    !isProductPackaged({
                        product: props.item.assistance,
                        stackInfos: trip?.stack_info ?? null
                    })
                }
                unmountOnExit
            >
                {
                    props.item.type === 'normal' &&
                    <CartConstructionProductsTableItemMargin
                        type="assistance"
                        item={props.item.assistance}
                    />
                }
                {
                    props.item.type === 'manual' &&
                    <CartConstructionProductsTableItemMargin
                        type="manual"
                        item={props.item.assistance}
                    />
                }
            </Collapse>
        </TableCell>
    );
}
